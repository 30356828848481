import './assets/css/CustomOrder.scss'
import {ReactComponent as PlusIcon} from '../Invoice/assets/image/plus.svg';
import React, {useState} from "react";
import StateModalModel from "../../components/Modal/Model/StateModalModel";
import ModalComponents from "../../components/Modal/Modal";
import CustomOrderModal from "./compoentns/CustomOrderModal";
import AppSettingController from "../Main/controller/AppSettingController";
import marketSettingLocalStorage from "../Main/interface/MarketSettingLocalStorage";

export default function CustomOrder() {
    const setting = AppSettingController.getSetting() as marketSettingLocalStorage
    const [stateModal, setStateModal] = useState<StateModalModel>({
        body: <CustomOrderModal/>,
        closeLabel: "",
        show: false,
        submit(): void {
        },
        submitLabel: "",
        modalClassName: "custom-order-modal",
        title: ""
    })

    if (!setting.customBuy)
        return null

    return (
        <>
            <div className="custom-order-container" onClick={() => setStateModal({...stateModal, show: true})}>
                <PlusIcon/>
            </div>
            <ModalComponents state={stateModal} setState={setStateModal}/>
        </>
    );
}