import Password from "../../../../../components/Password/Password";
import React, {useState} from "react";
import '../../../assets/ChangePasswordModal.scss'
import {ChangePasswordModalState} from "./interfaces/ChangePasswordModalState";
import ChangePasswordModalController from "./controller/ChangePasswordModalController";
import Toast from "../../../../../utility/toast";

export default function ChangePasswordModal() {
    const [state, setState] = useState<ChangePasswordModalState>({
        prevPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    })
    const passwordFields = [
        {
            keyState: "prevPassword",
            setState,
            label: "گذرواژه فعلی",
            placeholder: "گذرواژه فعلی",
        },
        {
            keyState: "newPassword",
            setState,
            label: "گذرواژه جدید",
            placeholder: "گذرواژه جدید",
        },
        {
            keyState: "confirmNewPassword",
            setState,
            label: "تکرار گذرواژه جدید",
            placeholder: "تکرار گذرواژه جدید",
        },
    ]

    return (
        <div className={'change-password-modal'}>
            <h2>تغییر گذرواژه</h2>
            <form onSubmit={e => new ChangePasswordModalController(state, setState).submitForm(e)}>
                {passwordFields.map(field => (
                    <Password key={field.keyState}
                              setState={field.setState}
                              label={field.label}
                              placeholder={field.placeholder}
                              keyState={field.keyState}/>
                ))}
                {state.error ?
                    <div className="error-change-password-modal">
                        <p>{state.message}</p>
                    </div>
                    : null}
                <div className="footer-change-password-modal">
                    <button type={'button'} onClick={() => window.dispatchEvent(new Event('closeModal'))}>
                        بستن
                    </button>
                    <button type="submit">تغییر گذرواژه</button>
                </div>
            </form>
        </div>
    )
}