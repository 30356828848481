import React from "react";
import DiscountFormState from "../interface/DiscountFormState";
import {toNumber} from "lodash";
import AppSettingController from "../../../../Main/controller/AppSettingController";

export default class DiscountFormController {
    protected state: DiscountFormState;
    protected setState: React.Dispatch<React.SetStateAction<DiscountFormState>>;

    constructor(state: DiscountFormState, setState: React.Dispatch<React.SetStateAction<DiscountFormState>>) {
        this.state = state;
        this.setState = setState;
    }

    async addDiscount(e: any) {
        e.preventDefault();
        if (!this.state.discountCode) return this.updateState({
            ...this.state,
            error: true,
            message: "کد تخفیف اجباری میباشد"
        });

        if (this.state.type === "price" && !this.state.discountPrice) return this.updateState({
            ...this.state,
            error: true,
            message: "مبلغ تخفیف اجباری میباشد"
        });

        if (this.state.type === "percentage" && !this.state.discountPercentage) return this.updateState({
            ...this.state,
            error: true,
            message: "درصد تخفیف اجباری میباشد"
        });

        this.setState({
            error: false,
            discountCode: "",
            discountPercentage: "",
            discountPrice: "",
            discountPriceSeparator: "",
            type: "percentage",
            message: "",
        })

        AppSettingController.addDiscount(
            this.state.discountCode,
            `${this.state.discountPercentage}`,
            `${this.state.discountPrice}`,
            this.state.type,
        )
    }

    changeType(e: React.ChangeEvent<HTMLSelectElement>) {
        e.preventDefault();
        this.updateState({type: e.target.value})
    }

    setDiscountCode(e: React.ChangeEvent<HTMLInputElement>) {
        this.updateState({discountCode: e.target.value})
    }

    setDiscount(e: React.ChangeEvent<HTMLInputElement>) {
        if (this.state.type === "percentage") {
            this.updateState({discountPercentage: e.target.value})
        } else {
            this.updateState({discountPrice: e.target.value})
        }
    }

    private updateState(state: object) {
        const newState = {...this.state, ...state};
        this.state = newState;
        this.setState(newState)
    }
}