import React, {useState} from "react";
import InvoiceState from "../interface/invoiceState";
import '../assets/css/SelectDesk.scss';
import AppSettingController from "../../Main/controller/AppSettingController";
import MarketSetting from "../../Main/interface/MarketSettingLocalStorage";
import InvoiceController from "../Controller/InvoiceController";

export default function SelectDesk({state, setState}: {
    state?: InvoiceState,
    setState?: React.Dispatch<React.SetStateAction<InvoiceState>>
}) {
    const setting = AppSettingController.getSetting() as MarketSetting;
    const [search, setSearch] = useState('');

    const filteredDesks = InvoiceController.filterDesks(search, setting.desk);

    if (!state || !setState)
        return null;

    return (
        <div className="select-desk-container">
            <h1>انتخاب میز</h1>
            <input className={`${!setting.desk?.length ? 'disabled-search-desk' : ''}`}
                   type="text"
                   placeholder={'جستجو'}
                   onChange={e => setSearch(e.target.value)}
                   disabled={!setting.desk?.length}/>
            <div className={`desk-list ${!filteredDesks?.length ? "empty-desk-list" : ""}`}>
                {filteredDesks?.length ? filteredDesks.map(item => (
                    <button key={item.id} onClick={() => setState({...state, deskActive: item})}>
                        {item.label}
                    </button>
                )) : <p className={'empty-desk'}>میزی تعریف نشده است.</p>}
            </div>
            <div className="footer-desk">
                <button className={'stroke-btn'} onClick={() => window.dispatchEvent(new Event('closeModal'))}>
                    بازگشت
                </button>
                <button className={'primary-btn'} onClick={() => setState({...state, takeaway: true})}>
                    بیرون بر
                </button>
            </div>
        </div>
    );
}
