export default class Ws {
    private socket: WebSocket | null = null;
    private isConnected: boolean = false;
    private messageQueue: string[] = [];

    constructor(private url: string) {}

    connect(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.socket = new WebSocket(this.url);

            this.socket.onopen = () => {
                this.isConnected = true;

                this.messageQueue.forEach((message) => this.send(message));
                this.messageQueue = [];

                resolve();
            };

            this.socket.onmessage = (event: MessageEvent) => {
                this.onMessage(event.data);
            };

            this.socket.onclose = (event: CloseEvent) => {
                this.isConnected = false;
                this.onClose(event.reason);
            };

            this.socket.onerror = (event: Event) => {
                console.error("WebSocket error:", event);
                this.onError(event);
                reject(event);
            };
        });
    }

    send(message: string): void {
        if (this.isConnected && this.socket) {
            this.socket.send(message);
        } else {
            this.messageQueue.push(message);
        }
    }

    close(): void {
        if (this.isConnected && this.socket) {
            this.socket.close();
            this.isConnected = false;
        }
    }

    public onMessage(message: string): void {
    }

    public onClose(reason: string): void {
    }

    public onError(error: Event): void {
    }
}
