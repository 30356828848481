import MarketRepository from "../../../Main/repository/MarketRepository";
import MarketSettingLocalStorage from "../../../Main/interface/MarketSettingLocalStorage";
import AppSettingController from "../../../Main/controller/AppSettingController";
import {useState} from "react";
import Toast from "../../../../utility/toast";

export default function PublicKeyNetbee() {
    const [state, setState] = useState<string>('')
    const setting = MarketRepository.getSettingMarket() as MarketSettingLocalStorage;

    const SaveHandler = () => {
        try {
            AppSettingController.setPublicKeyNetbee(state)
            Toast.success("کلید عمومی نت‌بی ‌پوز با موفقیت ذخیره شد")
        } catch (e) {
            Toast.error("خطا در ذخیره سازی کلید عمومی نت‌بی پوز")
        }
    }

    return (
        <section className="public-key-container">
            <p>کلید عمومی نت‌بی پوز</p>
            <div className={'public-key'}>
                <input defaultValue={setting.publicKeyNetbee} onChange={e => setState(e.target.value)}/>
                <button type="button" onClick={SaveHandler}>ذخیره</button>
            </div>
        </section>
    );
}
