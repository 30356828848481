import AppSettingController from "../../../Main/controller/AppSettingController";

export default function ChangeTheme() {
    const setting = AppSettingController.getSetting();

    return (
        <div className={"theme-colors"}>
            <p>شخصی سازی رنگ</p>
            <div className={"colors-selector"}>
                {setting.colorArray.map((color: number) => (
                    <div key={color}
                         className={`theme-${color} ${setting.color === color ? 'selected' : ''}`}
                         onClick={() => AppSettingController.changeThemeColor(color)}
                         data-theme={setting.themeMode}>
                        <button className={`theme-${color}  ${setting.themeMode}`}/>
                    </div>
                ))}
            </div>
        </div>
    )
}