import './assets/css/Invoice.scss'
import InvoiceList from "./components/InvoiceList";
import {useEffect, useState} from "react";
import AppSettingController from "../Main/controller/AppSettingController";
import MarketSetting from "../Main/interface/MarketSettingLocalStorage";
import SelectDesk from "./components/SelectDesk";
import InvoiceState from "./interface/invoiceState";

export default function Invoice() {
    const setting = AppSettingController.getSetting() as MarketSetting;
    const [state, setState] = useState<InvoiceState>({
        takeaway: false,
        loading: false
    })

    useEffect(() => {
        // validate number invoice in first time
        AppSettingController.checkInvoiceNum()
    }, []);

    if (!setting.activeDesk)
        return <InvoiceList data={state} setData={setState}/>

    if (state.takeaway || state.deskActive?.id)
        return <InvoiceList data={state} setData={setState}/>

    return (
        <SelectDesk state={state} setState={setState}/>
    );
}