import {useState} from "react";
import DeskFormState from "./interface/deskformState";
import DeskFormController from "./controller/DeskFormController";

export default function DeskForm() {
    const [state, setState] = useState<DeskFormState>({
        error: false,
        value: "",
        message: ""
    })

    return (
        <form className={`add-desk-form ${state.error ? "add-desk-form-error" : ""}`}
              onSubmit={e => new DeskFormController(state, setState).addDesk(e)}>
            <h3>افزودن میز جدید</h3>
            <div className={'inputs-container'}>
                <label>
                    <input type="text"
                           id={'desk-input'} value={state.value}
                           onChange={e => new DeskFormController(state, setState).addDeskInput(e)}/>
                    {state.error ? <p>{state.message}</p> : null}
                </label>
                <button type={'submit'} disabled={state.error}>افزودن</button>
            </div>
        </form>
    )
}