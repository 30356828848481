import './assets/css/produc-loading.scss'
import AppSettingController from "../../../../Main/controller/AppSettingController";

export default function ProductLoadingGrid({count = 8}: { count?: number }) {
    const setting = AppSettingController.getSetting();


    return (
        <>
            {Array.from({length: count}).map((_, index) => (
                <div className={`grid-product-loading ${setting.grid <= 2 ? 'large-height' : ''}`} key={index}>
                    <div className={'image'}/>
                    <div className={'footer'}>
                        <div className={'title-parent'}>
                            <span className={'title'}/>
                            <span className={'title'}/>
                        </div>

                        <div className={'price-parent'}>
                            <span className={'price'}/>
                            <div className={'btn-loader'}/>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )

}