import {useNavigate, useParams} from "react-router-dom";
import {ReactComponent as ArrowRightIcon} from "../assets/icons/arrow-right.svg";
import SettingController from "../controller/SettingController";
import {useContext} from "react";
import {MarketContext} from "../../Main/context/MarketContext";

export default function SettingHeader() {
    const {shopId} = useParams()
    const context = useContext(MarketContext)
    const navigate = useNavigate();

    if (!shopId) return null

    return (
        <section className={"home-header"}>
            <div className={"header-container"}>
                <div className={'close-button'}>
                    <button onClick={() => SettingController.backToHome(context, navigate, shopId)}>
                        <ArrowRightIcon/>
                    </button>
                </div>
                <div className={"store-name"}>
                    <h1>تنظیمات</h1>
                </div>
                <div/>
            </div>
        </section>
    )
}