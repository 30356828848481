import AppSettingController from "../../../Main/controller/AppSettingController";

export default function ToggleActiveDesk() {
    const setting = AppSettingController.getSetting();

    return (
        <div className={"dark-mode"}>
            <p>فعال سازی میز</p>
            <div className={"dark-mode-toggle"}>
                <label className="switch">
                    <input type="checkbox" checked={setting.activeDesk} onChange={() => AppSettingController.toggleActiveDesk()}/>
                    <span className="slider round"></span>
                </label>
            </div>
        </div>
    )
}