import {ProductState} from "../../Products/interfaces/ProductComponentState";
import MarketRepository from "../repository/MarketRepository";
import {find, get, toNumber} from "lodash";
import GenerateUUID from "../../../utility/generateUUID";

export default class AppCartController {

    static addItem(item: ProductState) {
        const prevItems = MarketRepository.getCartMarket();
        const isExistItem = AppCartController.isExistInCart(item);

        if (toNumber(get(isExistItem, "limit_add_to_cart", 0)) === get(isExistItem, "quantity"))
            return false

        const updatedItems = prevItems.items.map((cartItem: any) =>
            cartItem._id === item._id
                ? {...cartItem, quantity: get(cartItem, "quantity", 0) + 1}
                : cartItem
        );

        if (!isExistItem) {
            return MarketRepository.setCartMarket("items", [
                ...updatedItems,
                {...item, quantity: 1}
            ]);
        }

        return MarketRepository.setCartMarket("items", updatedItems);
    }

    static addCustomOrder(price: number) {
        const prevItems = MarketRepository.getCartMarket();

        const existingCustomOrder = find(prevItems.items, {customProduct: true, price});

        if (existingCustomOrder) {
            const updatedItems = prevItems.items.map((cartItem: any) =>
                cartItem._id === existingCustomOrder._id
                    ? {...cartItem, quantity: cartItem.quantity + 1}
                    : cartItem
            );

             MarketRepository.setCartMarket("items", updatedItems);
            return true
        }

        const customOrder = {
            limit_add_to_cart: "100",
            price: price,
            quantity: 1,
            total: 1000,
            status: true,
            customProduct: true,
            title: "محصول سفارشی",
            _id: GenerateUUID(),
        };

        MarketRepository.setCartMarket("items", [
            ...prevItems.items,
            customOrder
        ]);

        return this;
    }


    static removeItem(item: ProductState) {
        const prevItems = MarketRepository.getCartMarket();

        const isExistItem = AppCartController.isExistInCart(item);

        if (!isExistItem) return false;

        const updatedItems = prevItems.items.map((cartItem: any) => {
            if (cartItem._id === item._id) {
                const updatedQuantity = get(cartItem, "quantity", 0) - 1;

                if (updatedQuantity <= 0) {
                    return null;
                }

                return {...cartItem, quantity: updatedQuantity};
            }
            return cartItem;
        }).filter((cartItem: any) => cartItem !== null);

        return MarketRepository.setCartMarket("items", updatedItems);
    }

    static clearCart() {
        return MarketRepository.setCartMarket("items", []);
    }


    static isExistInCart(item: ProductState) {
        const prevItems = MarketRepository.getCartMarket();
        return find(prevItems.items, {_id: item._id});
    }
}