import MarketRepository from "../../../Main/repository/MarketRepository";
import MarketSettingLocalStorage from "../../../Main/interface/MarketSettingLocalStorage";
import Toast from "../../../../utility/toast";

export default function PublicKey() {
    const setting = MarketRepository.getSettingMarket() as MarketSettingLocalStorage;

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(setting.publicKey);
            Toast.success("کلید عمومی با موفقیت کپی شد!")
        } catch (err) {
            console.error("خطا در کپی کردن به کلیپ‌بورد:", err);
            Toast.error("خطا در کپی کردن کلید عمومی")
        }
    };

    return (
        <section className="public-key-container">
            <p>کلید عمومی مارکت</p>
            <div className={'public-key'}>
                <input disabled defaultValue={setting.publicKey} />
                <button type="button" onClick={handleCopy}>کپی</button>
            </div>
        </section>
    );
}
