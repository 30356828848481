import html2canvas from "html2canvas";
import MarketRepository from "../../Main/repository/MarketRepository";
import MarketCartLocalstorage from "../../Main/interface/MarketCartLocalstorage";
import AppSettingController from "../../Main/controller/AppSettingController";
import MarketSettingLocalStorage from "../../Main/interface/MarketSettingLocalStorage";
import PriceInterface from "../../Invoice/interface/priceInterface";
import {get, sumBy, toNumber} from "lodash";

export default class InvoiceGeneratorController {
    generateInvoiceHTML(price: PriceInterface, desk?: string): HTMLElement {
        const card = MarketRepository.getCartMarket() as MarketCartLocalstorage;
        const setting = AppSettingController.getSetting() as MarketSettingLocalStorage;
        const totalPrice = card.items?.length ? card.items.reduce((sum: number, item: any) =>
            sum + item.price * (item.quantity || 1), 0) : 0;
        const currentDate = new Date().toLocaleDateString('fa-IR');
        const currentTime = new Date().toLocaleTimeString('fa-IR', {hour: '2-digit', minute: '2-digit'});
        const currentDay = new Date().toLocaleDateString('fa-IR', {weekday: 'long'});
        const container = document.createElement('div');
        const quantity = sumBy(card.items, item => toNumber(get(item, "quantity", 0)));
        container.className = 'invoice-generator-container';

        container.innerHTML = `
  <div>
    <div class="invoice-header">
      <div class="invoice-header-names-container">
        <div class="data-names">
          <div class="name-shop fa-num">${setting.name}</div>
          <div class="data-date">
            <div class="day fa-num">${currentDay}</div>
            <div class="desk-name fa-num">${desk ? desk : 'بیرون بر'}</div>
          </div>
        </div>
      </div>
      <div class="invoice-header-date-container">
        <div class="date fa-num">تاریخ: ${currentDate}</div>
        <div class="date fa-num">ساعت: ${currentTime}</div>
        <div class="invoice-number"><span>فیش</span> <span class="number fa-num">${setting.receipt}</span></div>
      </div>
    </div>
    <div class="invoice-content">
      <table>
        <thead>
          <tr>
            <th>رد</th>
            <th style="width: 80%;">کالا</th>
            <th>تعداد</th>
            <th>قیمت</th>
            <th>جمع</th>
          </tr>
        </thead>
        <tbody>
          ${card.items?.length ? card.items
            .map((item, index) => `<tr>
                            <td>${index + 1}</td>
                            <td>${item.title}</td>
                            <td>${item.quantity || 1}</td>
                            <td>${item.price.toLocaleString('fa-IR')}</td>
                            <td>${((~~item.quantity || 1) * item.price).toLocaleString('fa-IR')}</td>
                            </tr>`).join('') : ``}
                  <tr>
                    <td colspan="2">جمع کل</td>
                    <td>${quantity}</td>
                    <td colspan="2">${price.total.toLocaleString('fa-IR')} تومان </td>
                  </tr>
                  <tr>
                    <td colspan="2">تخفیف</td>
                    <td colspan="3">${(price.total - price.regular).toLocaleString('fa-IR')} تومان </td>
                  </tr>
                </tbody>
              </table>
              <div class="total-price">
                <p>پرداختی:</p>
                <p class="fa-num">${price.regular.toLocaleString('fa-IR')} تومان</p>
              </div>
              ${setting.textUnderBill ? `<div class="footer-invoice">
               <p class="fa-num">${setting.textUnderBill}</p>
              </div>` : ''}
            </div>
            <div class="copy-right fa-num">
                <span>021-91035025</span>
                <span>netbee.online</span>
           </div>
          </div>
        `;


        return container;
    }

    async print(price: PriceInterface, desk?: string, ip?: string, port?: number) {
        const invoiceElement = this.generateInvoiceHTML(price, desk);

        document.body.appendChild(invoiceElement);

        const canvas = await html2canvas(invoiceElement)

        const base64image = canvas.toDataURL();
        const kiosk = get(window, "Kiosk", undefined) as any

        if (kiosk) {
            if (!ip)
                kiosk.print(base64image.replace("data:image/png;base64,", ""));
            if (ip)
                kiosk.printNet(ip, port, base64image.replace("data:image/png;base64,", ""))
        }

        document.body.removeChild(invoiceElement);
    }

    downloadImage(price: PriceInterface, desk?: string) {
        const invoiceElement = this.generateInvoiceHTML(price, desk);

        document.body.appendChild(invoiceElement);

        html2canvas(invoiceElement).then((canvas) => {
            const base64image = canvas.toDataURL("image/png");

            const link = document.createElement('a');
            link.href = base64image;
            link.download = 'invoice.png';
            link.click();

            document.body.removeChild(invoiceElement);
        });
    }
}
