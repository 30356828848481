export default class SignatureManager {
    static async sign(privateKeyPem: string, data: string): Promise<string> {
        const key = await crypto.subtle.importKey(
            "pkcs8",
            SignatureManager.pemToArrayBuffer(privateKeyPem),
            {
                name: "ECDSA",
                namedCurve: "P-256"
            },
            false,
            ["sign"]
        );

        const rawSignature = await crypto.subtle.sign(
            {
                name: "ECDSA",
                hash: {name: "SHA-256"}
            },
            key,
            new TextEncoder().encode(data)
        );

        const derSignature = SignatureManager.convertRawSignatureToDER(new Uint8Array(rawSignature));

        return btoa(String.fromCharCode(...derSignature as any));
    }

    static async verify(publicKeyPem: string, base64DerSignature: string, data: string): Promise<boolean> {
        const key = await crypto.subtle.importKey(
            "spki",
            SignatureManager.pemToArrayBuffer(publicKeyPem),
            {
                name: "ECDSA",
                namedCurve: "P-256"
            },
            false,
            ["verify"]
        );

        const derSignature = new Uint8Array(
            atob(base64DerSignature).split("").map(c => c.charCodeAt(0))
        );

        const rawSignature = SignatureManager.convertDERToRawSignature(derSignature);

        return crypto.subtle.verify(
            {
                name: "ECDSA",
                hash: {name: "SHA-256"}
            },
            key,
            rawSignature,
            new TextEncoder().encode(data)
        );
    }

    static convertDERToRawSignature(derSignature: Uint8Array): Uint8Array {
        if (derSignature[0] !== 0x30) {
            throw new Error("Invalid DER signature: does not start with SEQUENCE marker (0x30)");
        }

        const sequenceLength = derSignature[1];
        if (sequenceLength !== derSignature.length - 2) {
            throw new Error("Invalid DER signature: Sequence length mismatch");
        }

        let offset = 2;

        if (derSignature[offset] !== 0x02) {
            throw new Error("Invalid DER signature: missing INTEGER marker for R");
        }

        const rLength = derSignature[offset + 1];
        const rStart = offset + 2;
        const rEnd = rStart + rLength;

        if (rEnd > derSignature.length) {
            throw new Error(`Invalid DER signature: R length out of bounds (Declared: ${rLength}, Remaining: ${derSignature.length - rStart})`);
        }

        const r = derSignature.slice(rStart, rEnd);
        offset = rEnd;

        if (derSignature[offset] !== 0x02) {
            throw new Error("Invalid DER signature: missing INTEGER marker for S");
        }

        const sLength = derSignature[offset + 1];
        const sStart = offset + 2;
        const sEnd = sStart + sLength;

        if (sEnd > derSignature.length) {
            throw new Error(`Invalid DER signature: S length out of bounds (Declared: ${sLength}, Remaining: ${derSignature.length - sStart})`);
        }

        const s = derSignature.slice(sStart, sEnd);

        const rPadded = SignatureManager.padTo32Bytes(r) as any;
        const sPadded = SignatureManager.padTo32Bytes(s) as any;

        return new Uint8Array([...rPadded, ...sPadded]);
    }

    static convertRawSignatureToDER(rawSignature: Uint8Array): Uint8Array {
        const r = rawSignature.slice(0, rawSignature.length / 2);
        const s = rawSignature.slice(rawSignature.length / 2);

        const rDer = SignatureManager.integerToDER(r);
        const sDer = SignatureManager.integerToDER(s);

        const length = rDer.length + sDer.length + 4;
        return Uint8Array.of(
            0x30,
            length,
            0x02,
            rDer.length,
            ...rDer as any,
            0x02,
            sDer.length,
            ...sDer as any
        );
    }

    static pemToArrayBuffer(pem: string): ArrayBuffer {

        const binary = atob(pem);
        const arrayBuffer = new Uint8Array(binary.length);
        for (let i = 0; i < binary.length; i++) {
            arrayBuffer[i] = binary.charCodeAt(i);
        }
        return arrayBuffer.buffer;
    }


    static padTo32Bytes(value: Uint8Array): Uint8Array {
        const padded = new Uint8Array(32);
        if (value.length <= 32) {
            padded.set(value, 32 - value.length);
        } else {
            console.warn("Value is longer than 32 bytes; truncating.");
            padded.set(value.slice(-32));
        }
        return padded;
    }

    static integerToDER(intArray: Uint8Array): Uint8Array {
        let i = 0;
        while (i < intArray.length && intArray[i] === 0) i++;
        if (i === intArray.length) return Uint8Array.of(0);
        if (intArray[i] > 127) return Uint8Array.of(0, ...intArray.slice(i) as any);
        return intArray.slice(i);
    }
}
