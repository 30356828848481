import './assets/css/success.scss'
import Lottie from 'react-lottie';
import loadingAnimation from '../../assets/images/loading.json'
import {NavLink, useNavigate, useParams} from "react-router-dom";
import useCountDown from "../../utility/useCountDown";
import {useEffect} from "react";
import InvoiceGeneratorController from "../invoiceGenerator/controller/invoiceGeneratorController";
import AppCartController from "../Main/controller/AppCartController";

export default function Success() {
    const count = useCountDown(13);
    const navigate = useNavigate();
    const {shopId} = useParams();
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        if (!count) {
            navigate(`/${shopId}`, {replace: true});
        }
        setTimeout(() => AppCartController.clearCart(), 3000)
    }, [count]);

    return (
        <section className={'success-container'}>
            <div className={'content'}>
                <div className="logo">
                    <Lottie
                        options={defaultOptions}
                        isClickToPauseDisabled
                        height={120}
                        width={120}
                    />
                </div>
                <div className={'title'}>
                    <h1>پرداخت با موفقیت انجام شد</h1>
                    <p>لطفا رسید را از کارتخوان بردارید</p>
                </div>
                <div className="footer">
                    <NavLink to={`/${shopId}`} onClick={() => AppCartController.clearCart()}>بازگشت</NavLink>
                    <p>بازگشت خودکار در {count} ثانیه دیگر</p>
                </div>
            </div>
        </section>
    )
}