import {random} from "lodash";
import MarketRepository from "../repository/./MarketRepository";
import MarketSetting from "../interface/MarketSettingLocalStorage";
import React from "react";
import EventController from "../../../controller/EventController";
import EventListenerRepository from "../../../repository/System/EventListenerRepository";
import AppSettingController from "./AppSettingController";
import AppCartController from "./AppCartController";
import CreateKey from "../../Pay/controller/CreateKey";

export default class MarketController {
    protected state: number
    protected setState: React.Dispatch<React.SetStateAction<number>>

    constructor(state: number, setState: React.Dispatch<React.SetStateAction<number>>) {
        this.state = state;
        this.setState = setState;
    }

    init() {
        // Register Re Render
        this.registerRerender();

        this.reRender();
        this.start();

        return this.uninstallRerender();
    }

    static async marketInit() {
        const data = MarketRepository.getSettingMarket() as MarketSetting

        // set public key and private key
        AppSettingController.setKeys()

        // validate number invoice in first time
        AppSettingController.checkInvoiceNum()

        MarketRepository.cartInit();
        MarketRepository.orderInit();
        const hashPassword = await AppSettingController.passwordHash("master")
        AppSettingController.setDarkMode(data.themeMode)
        AppSettingController.changeThemeColor(data.color)
        MarketRepository.setSettingMarket("password", data.password ? data.password : hashPassword);
        MarketRepository.setSettingMarket("expiredDate", data.expiredDate ? data.expiredDate : AppSettingController.getExpiredDateOrder());
    }

    start() {
        const dispatches = ["marketInit"]

        dispatches.map(k => EventController.dispatch(k))
    }

    reRender() {
        this.setState(random(999999999))
    }

    registerRerender() {
        // Register Events
        (new EventListenerRepository()).register();

        window.addEventListener('reRender', this.reRender.bind(this), false)
    }

    uninstallRerender() {
        // Register Events
        (new EventListenerRepository()).uninstall();

        window.removeEventListener('reRender', this.reRender.bind(this), false)
    }
}
