import {ReactComponent as PlusIcon} from '../assets/image/plus.svg';

export default function DiscountRow({discount, setDiscount}: { discount: any, setDiscount: any }) {
    return (
        <div className="toggle-form-discount"
             onClick={e => setDiscount({...discount, show: !discount.show})}>
            <p>کد تخفیف دارید ؟</p>
            <PlusIcon className={discount.show ? 'active' : 'de-active'}/>
        </div>
    );
}