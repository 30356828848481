import React, {memo, useContext, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import ProductController from "./controller/ProductController";
import ProductList from "./view/component/ProductList";
import ProductGrid from "./view/component/ProductGrid";
import ProductError from "./view/component/ProductError";
import {ProductComponentState, ProductState} from "./interfaces/ProductComponentState";
import {get} from "lodash";
import {MarketContext} from "../Main/context/MarketContext";
import AppSettingController from "../Main/controller/AppSettingController";
import ProductLoadingGrid from "./view/component/Loading/ProductLoadingGrid";
import ProductLoadingList from "./view/component/Loading/ProductLoadingList";

export default function Products() {
    const setting = AppSettingController.getSetting();
    const productController = useRef<ProductController | null>(null);
    const {shopId} = useParams();
    const context = useContext(MarketContext);
    const categoryId = context.state.categoryActive === 'all' ? "" : context.state.categoryActive
    const [state, setState] = useState<ProductComponentState>({
        loading: true,
        products: [],
        error: false,
        currentPage: 1,
        totalPage: 1,
        hasMore: true,
    });

    useEffect(() => {
        const handleScroll = () => {
            productController.current?.handleScroll(shopId!, categoryId);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [shopId, state]);

    useEffect(() => {
        productController.current = new ProductController(state, setState);
        productController.current.initProduct(categoryId, shopId).then();

        const handleScroll = () => {
            productController.current?.handleScroll(shopId!, categoryId);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [categoryId, shopId]);

    if (state.error)
        return <ProductError message={get(state, "message", "")} productController={productController.current}/>;

    return (
        <>

            <section
                className={get(setting, "isList") ? "list-section" : `grid-section-${get(setting, "grid")}`}>
                {state.loading && state.currentPage === 1 ?
                    get(setting, "isList") ? <ProductLoadingList/> : <ProductLoadingGrid count={12}/>
                    : null}

                {state.products.map((item: ProductState) => (
                    get(setting, "isList") ?
                        <ProductList item={item} key={item._id}/> :
                        <ProductGrid item={item} key={item._id}/>
                ))}
            </section>
            {state.loading ? <div className={`loading`}>
                <div className="spinner"/>
            </div> : null}
        </>
    );
}
