import {Spinner, Table} from "react-bootstrap";
import ProductController from "../../Products/controller/ProductController";
import ModalComponents from "../../../components/Modal/Modal";
import React, {useEffect, useState} from "react";
import AppSettingController from "../../Main/controller/AppSettingController";
import MarketRepository from "../../Main/repository/MarketRepository";
import MarketCartLocalstorage, {ProductState} from "../../Main/interface/MarketCartLocalstorage";
import {find, get, sumBy, toNumber} from "lodash";
import StateModalModel from "../../../components/Modal/Model/StateModalModel";
import Confirm from "./Confirm";
import {ReactComponent as MinusIcon} from './../assets/image/minus.svg';
import {ReactComponent as TrashIcon} from './../assets/image/trash.svg';
import {ReactComponent as ArrowRightIcon} from './../assets/image/arrowRight.svg';
import InvoiceState from "../interface/invoiceState";
import MarketSetting from "../../Main/interface/MarketSettingLocalStorage";
import InvoiceController from "../Controller/InvoiceController";
import {useNavigate, useParams} from "react-router-dom";
import {ReactComponent as PlusIcon} from '../assets/image/plus.svg';
import DiscountForm from "./DiscountForm";
import DiscountRow from "./DiscountRow";
import PriceInterface from "../interface/priceInterface";
import ShowDiscount from "./ShowDiscount";
import ProductImage from "./ProductImage";

export default function InvoiceList({data, setData}: {
    data: InvoiceState,
    setData: React.Dispatch<React.SetStateAction<InvoiceState>>
}) {
    const setting = AppSettingController.getSetting() as MarketSetting;
    const card = MarketRepository.getCartMarket() as MarketCartLocalstorage;
    const now = new Date().toLocaleDateString('fa-IR');
    const nowClock = new Date().toLocaleTimeString('fa-IR');
    const priceSum = sumBy(card.items, item => item.price * toNumber(get(item, "quantity", 0)));
    const [state, setState] = useState(false)
    const {shopId} = useParams();
    const navigate = useNavigate();
    const [stateModal, setStateModal] = useState<StateModalModel>({
        body: <Confirm/>,
        closeLabel: "",
        show: false,
        submit(): void {
        },
        submitLabel: "",
        title: "",
        modalClassName: "confirm",
        bsPrefix: "confirm-background modal"
    })
    const [price, setPrice] = useState<PriceInterface>({
        total: priceSum,
        regular: priceSum,
        discountType: "",
        isApplyDiscount: false
    })
    const [discount, setDiscount] = useState({
        show: false
    })
    const [notAvailableProduct, setNotAvailableProduct] = useState([])

    useEffect(() => {
        InvoiceController.discountCalculation(price, setPrice)
    }, [state]);

    useEffect(() => {
        setNotAvailableProduct([])
    }, [state]);

    return (
        <div className="invoice-container">
            <div className={'title'}>
                {setting.activeDesk ?
                    <ArrowRightIcon onClick={() => new InvoiceController(data, setData).backToSelectDesk()}/> : null}
                <h1>صورتحساب</h1>
            </div>
            <div className="top-table">
                <p>فیش : <span>{setting.receipt}</span>
                    {setting.activeDesk ? <span> ({new InvoiceController(data, setData).getDeskName()})</span> : null}
                </p>
                <time>تاریخ: {now} - {nowClock.slice(0, -3)}</time>
            </div>
            <div className={'payment-form'}>
                <Table>
                    <thead>
                    <tr>
                        <th>نام محصول</th>
                        <th>تعداد</th>
                        <th>قیمت</th>
                        <th>عملیات</th>
                    </tr>
                    </thead>
                    <tbody>
                    {card.items?.map(item => {
                        const isCan = find(notAvailableProduct, {_id: item._id});
                        return (
                            <tr key={item._id}>
                                <td className={'name-product'}>
                                    <div>
                                        <ProductImage image={item.image}/>
                                        <span className={isCan ? 'error' : ''}>{item.title}</span>
                                    </div>
                                </td>
                                <td className={'flex-center'}>
                                    <div>{item.quantity}</div>
                                </td>
                                <td className={'flex-center'}>
                                    <div>{item.price.toLocaleString('fa-IR')}</div>
                                </td>
                                <td className={'management'}>
                                    <div>
                                        <PlusIcon
                                            onClick={() => ProductController.addToInvoiceCart(item, state, setState, price, setPrice)}/>
                                        {~~item.quantity > 1 ? (
                                            <MinusIcon
                                                onClick={() => ProductController.removeToInvoiceCart(item, state, setState, price, setPrice)}/>
                                        ) : (
                                            <TrashIcon className={'delete-item'}
                                                       onClick={() => ProductController.removeToInvoiceCart(item, state, setState, price, setPrice)}/>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </div>
            {notAvailableProduct.length ? <p className={'error-inventory'}>موجودی انبار کافی نیست</p> : null}
            {!data.loading && discount.show ? <DiscountForm price={price} setPrice={setPrice}
                                                            nonExistentProduct={!!notAvailableProduct.length}/> : null}
            <div
                className={`footer-payment ${data.loading ? 'footer-payment-loading' : ''} ${discount.show || notAvailableProduct.length? 'mt-0' : ''}`}>
                {!data.loading ? <>
                    {price.isApplyDiscount ?
                        <ShowDiscount price={price} setPrice={setPrice} setDiscount={setDiscount}/> :
                        <DiscountRow discount={discount} setDiscount={setDiscount}/>}

                    <div className={`price ${price.isApplyDiscount ? "align-items-start" : ""}`}>
                        <p>مبلغ قابل پرداخت:</p>
                        <div className="price-content">
                            <span>تومان</span>
                            <div>
                                {price.isApplyDiscount ? <span>
                                    {price.regular.toLocaleString('fa-IR')}
                                </span> : null}
                                <span className={price.isApplyDiscount ? 'prev-price' : ''}>
                                    {priceSum.toLocaleString('fa-IR')}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={'actions'}>
                        <button className={'refuse'} onClick={() => setStateModal({...stateModal, show: true})}>
                            انصراف
                        </button>
                        <button className={'payment-btn'}
                                onClick={() => new InvoiceController(data, setData).sendPayRequest(price, navigate, shopId as string, setNotAvailableProduct as any)}>
                            پرداخت
                        </button>
                    </div>
                </> : <div className={'loading'}>
                    <Spinner/>
                    <p>در انتظار پرداخت</p>
                </div>}
            </div>
            <ModalComponents state={stateModal} setState={setStateModal}/>
        </div>
    );
}