import ToggleThemeMode from "./view/Theme/ToggleThemeMode";
import ChangeTheme from "./view/Theme/ChangeTheme";
import LayoutProduct from "./view/Theme/LayoutProduct";

export default function Theme() {

    return (
        <section className={"design-field-container"}>
            <div className={"theme-container"}>
                <ToggleThemeMode/>
                <ChangeTheme/>
            </div>
            <div className={"shop-arrangement-container"}>
                <LayoutProduct/>
            </div>
        </section>
    );
}
