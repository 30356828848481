import {ReactComponent as ListIcon} from "../../assets/images/icons/list.svg";
import {ReactComponent as SettingIcon} from "../../assets/images/icons/setting.svg";
import {ReactComponent as GridIcon} from "../../assets/images/icons/grid.svg";
import {useParams} from "react-router-dom";
import ProductController from "../../../Products/controller/ProductController";
import React, {useState} from "react";
import StateModalModel from "../../../../components/Modal/Model/StateModalModel";
import ModalComponents from "../../../../components/Modal/Modal";
import CheckPassModal from "./CheckPassModal/CheckPassModal";
import AppSettingController from "../../../Main/controller/AppSettingController";
import marketSettingLocalStorage from "../../../Main/interface/MarketSettingLocalStorage";
import {get} from "lodash";


export default function HomeHeader() {
    const [stateModal, setStateModal] = useState<StateModalModel>({
        body: <CheckPassModal/>,
        closeLabel: "",
        show: false,
        submit(): void {
        },
        submitLabel: "",
        title: "",
        modalClassName: "password-modal-component",
    })

    const {shopId} = useParams()
    const setting = AppSettingController.getSetting() as marketSettingLocalStorage

    return (
        <section className={"home-header"}>
            <div className={"header-container"}>
                <div className={"store-name"}>
                    <h1>{get(setting, "name", "فروشگاه")}</h1>
                </div>
                {shopId ? <div className="setting">
                    <div onClick={() => ProductController.productArrangementToggle()}>
                        {setting.isList ? <GridIcon/> : <ListIcon/>}
                    </div>
                    <SettingIcon onClick={() => setStateModal({...stateModal, show: true})}/>
                </div> : null}
            </div>
            <ModalComponents state={stateModal} setState={setStateModal}/>
        </section>

    )
}