import '../assets/css/ConfirmInvoice.scss'
import AppCartController from "../../Main/controller/AppCartController";

export default function Confirm() {
    const clearCart = () => {
        AppCartController.clearCart()
        window.dispatchEvent(new Event('closeModal'))
    }

    return (
        <div className="confirm-invoice-container">
            <h3>پاک کردن سبد خرید</h3>
            <p>
                آیا مطمئن هستید که می‌خواهید سبد خرید خود را پاک کنید؟ در غیر این صورت می‌خواهید به صفحه اصلی بازگردید؟
            </p>
            <div className="footer">
                <button className={'stroke-btn'} onClick={() => window.dispatchEvent(new Event('closeModal'))}>
                    بازگشت به صفحه اصلی
                </button>
                <button className={'primary-btn'} onClick={() => clearCart()}>پاک کردن سبد خرید
                </button>
            </div>
        </div>
    );
}