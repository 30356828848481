import LocalStorageRepository from "../../../repository/LocalStorageRepository";
import PanelSettings from "../interface/MarketSettingLocalStorage";
import {set} from "lodash";
import MarketSetting from "../interface/MarketSettingLocalStorage";
import MarketCartLocalstorage from "../interface/MarketCartLocalstorage";
import RequestHandler from "../../../services/RequestHandler";
import MarketOrderLocalstorage from "../interface/MarketOrderLocalstorage";

export default class MarketRepository {

    static getSettingMarket(): PanelSettings {
        try {
            const defaultValue = JSON.stringify(MarketRepository.DefaultPanelSetting())
            const localStorage = LocalStorageRepository.get("setting", defaultValue)
            return JSON.parse(localStorage as string)
        } catch (e) {
            return this.DefaultPanelSetting();
        }
    }

    static async getSetting(shopId: string) {
        return await RequestHandler.get(`/shops/${shopId}`)
    }

    static getCartMarket() {
        try {
            const defaultValue = JSON.stringify(MarketRepository.DefaultCart())
            const localStorage = LocalStorageRepository.get("cart", defaultValue)
            return JSON.parse(localStorage as string)
        } catch (e) {
            return this.DefaultCart();
        }
    }

    static setSettingMarket(key: string, value: any) {
        const data = MarketRepository.getSettingMarket()
        set(data, key, value)
        LocalStorageRepository.set("setting", data)
    }

    static setCartMarket(key: string, value: any) {
        const data = MarketRepository.getCartMarket()
        set(data, key, value)
        LocalStorageRepository.set("cart", data)
    }

    static cartInit() {
        LocalStorageRepository.set("cart", this.getCartMarket())
    }

    static getOrdersMarket(): MarketOrderLocalstorage[] {
        try {
            const defaultValue = JSON.stringify(MarketRepository.DefaultOrders())
            const localStorage = LocalStorageRepository.get("orders", defaultValue)
            return JSON.parse(localStorage as string)
        } catch (e) {
            return this.DefaultOrders();
        }
    }

    static orderInit() {
        LocalStorageRepository.set("orders", this.getOrdersMarket())
    }

    private static DefaultPanelSetting(): MarketSetting {
        return {
            isList: false,
            gridNumber: [1, 2, 3, 4, 5],
            grid: 3,
            colorArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
            color: 1,
            themeMode: "light",
            activeDesk: false,
            customBuy: false,
            receipt: 100,
            password: "",
            privateKey: "",
            publicKey: "",
            name: "",
            phone: "",
            textUnderBill: "",
            basicReceiptInvoice: 100,
            api_key: "",
            discount: [],
            printer: [],
            publicKeyNetbee: "MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEjWrmg+dm7+Kk0cX3PJcP7OMv8KalJfvcWvrf6O2wXaNaD0BK3htCMszWhy3uAc9Wyudtq49sM9P7oPELaDhJwA==",
            desk: []
        }
    }

    private static DefaultCart(): MarketCartLocalstorage {
        return {
            items: []
        }
    }

    private static DefaultOrders(): MarketOrderLocalstorage[] {
        return []
    }
}