import React from "react";
import DeskFormState from "../interface/deskformState";
import AppSettingController from "../../../../../Main/controller/AppSettingController";

export default class DeskFormController {
    protected state: DeskFormState;
    protected setState: React.Dispatch<React.SetStateAction<DeskFormState>>;

    constructor(state: DeskFormState, setState: React.Dispatch<React.SetStateAction<DeskFormState>>) {
        this.state = state;
        this.setState = setState;
    }

    addDesk(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const input = document.getElementById("desk-input");

        if (!this.state.value) return this.updateState({...this.state, error: true, message: "نام میز اجباری میباشد."});

        if (AppSettingController.addDesk(this.state.value)) {
            if (input)
                input.blur();
            return this.updateState({error: false, message: "", value: ""});
        }

        return this.updateState({...this.state, error: true, message: "خطا در ایجاد میز حدید."})
    }

    addDeskInput(e: React.ChangeEvent<HTMLInputElement>) {
        this.updateState({...this.state, error: false, message: "", value: e.target.value})
    }

    private updateState(state: object) {
        const newState = {...this.state, ...state};
        this.state = newState;
        this.setState(newState)
    }
}