import MarketOrderLocalstorage from "../interface/MarketOrderLocalstorage";
import {get, isNull} from "lodash";
import MarketRepository from "../repository/MarketRepository";
import LocalStorageRepository from "../../../repository/LocalStorageRepository";
import generateUUID from "../../../utility/generateUUID";

export default class AppOrdersController {
    static getSetting(key: string | null = null, defaultValue: string | boolean | null = null) {
        if (!key) return MarketRepository.getOrdersMarket()

        const data = get(MarketRepository.getOrdersMarket(), key)

        if (isNull(data))
            return defaultValue

        return data
    }

    static setOrder(order: MarketOrderLocalstorage) {
        LocalStorageRepository.set("orders", [
            ...this.getSetting(),
            {
                id: generateUUID(),
                ...order
            }
        ])
        return true
    }

    static deleteOrder(order: MarketOrderLocalstorage) {
        const orders = this.getSetting();
        const updatedOrders = orders.filter((o: any) => o.id !== order.id);
        LocalStorageRepository.set("orders", updatedOrders);
        return true;
    }

}