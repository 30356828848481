import React, {useState} from 'react';
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch';
import {ImageZoomState} from "../interfaces/ImageZoomState";
import ImageZoomController from "../controller/ImageZoomController";
import ImageZoomControls from "./component/ImageZoomControls";

export default function ImageZoom({image}: { image: String }) {
    const [state, setState] = useState<ImageZoomState>({
        isZoom: false,
        isPanning: false,
    })


    return (
        <div className={"wrapper-container"}>
            <TransformWrapper
                minScale={1}
                maxScale={3}
                initialScale={1}
                panning={{disabled: !state.isPanning}}
                pinch={{disabled: false}}
                doubleClick={{disabled: true}}
                limitToBounds={true}
                onZoom={(e) => new ImageZoomController(state, setState).handleZoomChange(e.state.scale, e.resetTransform)}
                onZoomStop={(e) => new ImageZoomController(state, setState).handleZoomChange(e.state.scale, e.resetTransform)}
                onWheelStart={(e) => new ImageZoomController(state, setState).handleZoomChange(e.state.scale, e.resetTransform)}
                onPanningStart={(e) => new ImageZoomController(state, setState).handleZoomChange(e.state.scale, e.resetTransform)}
                onPinchingStart={(e) => new ImageZoomController(state, setState).handleZoomChange(e.state.scale, e.resetTransform)}
            >
                {({zoomIn, resetTransform}) => (
                    <>
                        <ImageZoomControls
                            zoomState={state}
                            onDoubleClick={() =>
                                new ImageZoomController(state, setState).handleDoubleClick(zoomIn, resetTransform)}
                        />
                        <TransformComponent>
                            <div style={{backgroundImage: `url(${image})`,}}
                                onDoubleClick={() =>
                                    new ImageZoomController(state, setState).handleDoubleClick(zoomIn, resetTransform)}
                            />
                        </TransformComponent>
                    </>
                )}
            </TransformWrapper>
        </div>
    );
}
