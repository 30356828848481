import {ImageZoomState} from "../../interfaces/ImageZoomState";
import {ReactComponent as ZoomOut} from '../../../../../../Home/assets/images/icons/zoomOut.svg';
import {ReactComponent as ZoomIn} from '../../../../../../Home/assets/images/icons/zoomIn.svg';

export default function ImageZoomControls({onDoubleClick, zoomState}: {
    onDoubleClick: () => void,
    zoomState: ImageZoomState
}) {
    return (
        <div className="zoom" onClick={(onDoubleClick)}>
            {zoomState.isZoom ? <ZoomOut/> : <ZoomIn/>}
        </div>
    )


}