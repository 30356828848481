import {get, keys} from "lodash";
import MarketController from "../../features/Main/controller/MarketController";
import InvoiceController from "../../features/Invoice/Controller/InvoiceController";

export default class EventListenerRepository {
    list: {}

    constructor() {
        this.list = {
            "marketInit": MarketController.marketInit.bind(this),
            "syncServer": () => InvoiceController.setOrderServer()
        }
    }

    register() {
        keys(this.list).map(key => {
            return window.addEventListener(key, get(this.list, key), false)
        })
    }

    uninstall() {
        keys(this.list).map(key => {
            return window.removeEventListener(key, get(this.list, key), false)
        })
    }
}