import React from "react";
import {CategoryComponentInterface} from "../interfaces/CategoryState";
import CategoryRepository from "../repository/CategoryRepository";
import {get} from "lodash";
import MarketContext from "../../Main/interface/MarketContext";

export default class CategoryController {
    protected state: CategoryComponentInterface;
    protected repository: CategoryRepository;
    protected setState: React.Dispatch<React.SetStateAction<CategoryComponentInterface>>;

    constructor(state: CategoryComponentInterface, setState: React.Dispatch<React.SetStateAction<CategoryComponentInterface>>) {
        this.state = state;
        this.setState = setState;
        this.repository = new CategoryRepository();
    }

    async getCategories(shopId?: string) {
        if (!shopId)
            return null;
        this.updateState({loading: true});
        const data = await this.repository.all(shopId)

        if (get(data, "status", false)) {
            this.updateState({loading: false, categories: get(data.data, "data", [])});
        }
    }

    setSelectedCategory(categoryId: string, context: MarketContext) {
        context.setState(prevState => ({...prevState, categoryActive: categoryId}));
        this.updateState({activeCategory: categoryId});
    }

    private updateState(state: object) {
        const newState = {...this.state, ...state};
        this.state = newState;
        this.setState(newState)
    }
}
