import {get, sortBy} from "lodash";
import {ReactComponent as FilterIcon} from "../../assets/icons/filter.svg";
import {ReactComponent as DeleteIcon} from "../../assets/icons/delete.svg";
import {Dropdown} from "react-bootstrap";
import {useState} from "react";
import AppSettingController from "../../../Main/controller/AppSettingController";

export default function DeskList() {
    const setting = AppSettingController.getSetting();
    const [sort, setSort] = useState<string>()

    return (
        <div className="desk-list">
            <div className={'desk-list-header'}>
                <h3>لیست میز ها</h3>
                <Dropdown
                    onSelect={(evt: string | null) => setSort(evt as string)}>
                    <Dropdown.Toggle id="dropdown-basic">
                        <FilterIcon/>
                        <p>مرتب سازی بر اساس :</p>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item eventKey="label">حروف الفبا</Dropdown.Item>
                        <Dropdown.Item eventKey="date">تاریخ</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="desk-list-item">
                {sortBy(get(setting, "desk", []), [sort]).map((item: { id: string, label: string, date: Date }) => (
                    <div key={item.id} className={'desk-item'}>
                        <p>{item.label}</p>
                        <DeleteIcon onClick={e => AppSettingController.deleteDesk(item.id)}/>
                    </div>
                ))}
            </div>
        </div>
    )
}