import './assets/css/produc-loading.scss'
import AppSettingController from "../../../../Main/controller/AppSettingController";

export default function ProductLoadingList({count = 8}: { count?: number }) {
    const setting = AppSettingController.getSetting();


    return (
        <>
            {Array.from({length: count}).map((_, index) => (
                <div className={`list-product-loading ${setting.grid <= 2 ? 'large-height' : ''}`} key={index}>
                    <div className="content">
                        <div className="data">
                            <div className="title"/>
                            <div className="desc">
                                <span/>
                                <span/>
                                <span/>
                                <span/>
                                <span/>
                            </div>
                        </div>
                        <div className="price"/>
                    </div>
                    <div className="image-parent">
                        <div className="image"/>
                        <div className="btn-loader"/>
                    </div>
                </div>
            ))}
        </>
    )

}