import React from "react";
import {ReceiptState} from "../interfaces/ReceiptState";
import MarketController from "../../../../../Main/controller/MarketController";
import Toast from "../../../../../../utility/toast";
import AppSettingController from "../../../../../Main/controller/AppSettingController";

export default class ReceiptController {
    protected state: ReceiptState;
    protected setState: React.Dispatch<React.SetStateAction<ReceiptState>>;

    constructor(state: ReceiptState, setState: React.Dispatch<React.SetStateAction<ReceiptState>>) {
        this.state = state;
        this.setState = setState;
    }

    saveFirstReceipt(e: React.FormEvent<HTMLElement>) {
        e.preventDefault()
        this.updateState({error: false})

        if (this.state.value <= 0 || !this.state.value) {
            Toast.error("خطا در ذخیره سازی شماره اولین فیش.")
            return this.updateState({error: true, message: 'شماره اولین فیش نامعتبر است.'})
        }

        AppSettingController.changeReceiptBasicNumber(this.state.value)
        Toast.success("شماره اولین فیش با موفقیت تغییر یافت.")
    }

    receiptInput(e: React.ChangeEvent<HTMLInputElement>) {
        this.updateState({value: e.currentTarget.value})
    }

    private updateState(state: object) {
        const newState = {...this.state, ...state};
        this.state = newState;
        this.setState(newState)
    }
}
