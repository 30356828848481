import {Dropdown} from "react-bootstrap";
import {get} from "lodash";
import AppSettingController from "../../../Main/controller/AppSettingController";
import {ReactComponent as DeleteIcon} from "../../assets/icons/delete.svg";
import MarketSettingLocalStorage from "../../../Main/interface/MarketSettingLocalStorage";

export default function DiscountCodsList() {
    const setting = AppSettingController.getSetting() as MarketSettingLocalStorage;


    return (
        <div className="discount-cods-list-container">
            <div className="desk-list">
                <div className={'desk-list-header'}>
                    <h3>لیست کد‌ تخفیف</h3>
                </div>
                <div className="desk-list-item">
                    {get(setting, "discount", []).map((item: {
                        id: string,
                        discountCode: string,
                        discountPercentage: string,
                        discountPrice: string,
                        type: string,
                    }) => (
                        <div key={`${item.discountCode}-${item.discountPercentage}`} className={'desk-item'}>
                            <p>کد
                                تخفیف: {item.discountCode} - {item.discountPrice ? Number(item.discountPrice).toLocaleString('fa-IR') : "%" + item.discountPercentage}</p>
                            <DeleteIcon onClick={e => AppSettingController.deleteDiscount(item.id)}/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}