import React, {useState} from "react";
import StateModalModel from "../../../../components/Modal/Model/StateModalModel";
import ModalComponents from "../../../../components/Modal/Modal";
import ChangePasswordModal from "./ChangePasswordModal/ChangePasswordModal";

export default function ChangePassword() {
    const [stateModal, setStateModal] = useState<StateModalModel>({
        body: <ChangePasswordModal/>,
        closeLabel: "",
        show: false,
        submit(): void {
        },
        submitLabel: "",
        title: ""
    })

    return (
        <>
            <section>
                <p>گذرواژه</p>
                <button type="submit" onClick={() => setStateModal({...stateModal, show: true})}>
                    تغییر گذرواژه
                </button>
            </section>
            <ModalComponents state={stateModal} setState={setStateModal}/>
        </>
    )
}