import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {CategoryComponentInterface, CategoryState} from "./interfaces/CategoryState";
import CategoryController from "./controller/CategoryController";
import {MarketContext} from "../Main/context/MarketContext";
import MarketRepository from "../Main/repository/MarketRepository";
import BuyToolbar from "../Home/view/component/BuyToolbar/BuyToolbar";
import CustomOrder from "../customOrder/customOrder";

export default function Category() {
    const {shopId} = useParams();
    const context = useContext(MarketContext)
    const card = MarketRepository.getCartMarket();
    const [state, setState] = useState<CategoryComponentInterface>({
        loading: false,
        categories: [],
        error: false,
        activeCategory: 'all',
    })

    useEffect(() => {
        new CategoryController(state, setState).getCategories(shopId).then()
    }, []);

    if (state.loading || !state.categories.length)
        return null;

    return (
        <>
            <BuyToolbar/>
            <nav className={`category-container ${card.items.length ? 'active-buy-toolbar' : ''}`}>
                <ul>
                    <li onClick={() => new CategoryController(state, setState).setSelectedCategory('all', context)}
                        className={`${context.state.categoryActive === 'all' ? "active-category" : ""}`}>
                        <span>همه</span>
                    </li>

                    {state.categories.map((item: CategoryState) => (
                        <li key={item._id}
                            onClick={() => new CategoryController(state, setState).setSelectedCategory(item._id, context)}
                            className={`${item._id === context.state.categoryActive ? "active-category" : ""}`}>
                            <span>{item.name}</span>
                        </li>
                    ))}
                </ul>
                <CustomOrder/>
            </nav>
        </>
    )
}
