import PrintForm from "./view/Print/PrintForm";
import './assets/icons/addPrinter.scss'
import PrinterList from "./view/Print/DiscountCodsList";

export default function Printer() {
    return (
        <section className={"print-container"}>
            <section>
                <PrintForm/>
                <PrinterList/>
            </section>
        </section>
    );
}