import {get, toNumber} from "lodash";
import React, {useState} from "react";
import {ProductState} from "../../../interfaces/ProductComponentState";
import AppCartController from "../../../../Main/controller/AppCartController";
import {ReactComponent as PlusIcon} from "./assets/image/add.svg";
import {ReactComponent as MinusIcon} from "./assets/image/remove.svg";
import {AnimatePresence, motion} from "framer-motion";
import ProductController from "../../../controller/ProductController";

export default function BtnAddToCart({item}: { item: ProductState }) {
    const isExistProductInCart = AppCartController.isExistInCart(item);
    const [state, setState] = useState(false)

    if (!isExistProductInCart)
        return (
            <motion.button
                className={`${toNumber(item.total) > 0 ? 'available' : 'not-available'}`}
                onClick={() => ProductController.addToCart(item, state, setState)}
                animate={{
                    opacity: toNumber(item.total) > 0 ? 1 : 0.5,
                    transition: {duration: 0.3},
                }}>
                {toNumber(item.total) > 0 ? 'افزودن' : 'ناموجود'}
            </motion.button>
        )

    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{height: 0, opacity: 0}}
            style={{overflow: "hidden"}}
            className={'quantity'}>
            <motion.button className="quantity-increase"
                           onClick={() => ProductController.addToCart(item, state, setState)}>
                <PlusIcon/>
            </motion.button>
            <AnimatePresence mode="popLayout">
                <motion.p>
                    {get(isExistProductInCart, 'quantity')}
                </motion.p>
            </AnimatePresence>
            <motion.button className="decrease" onClick={() => ProductController.removeToCart(item, state, setState)}>
                <MinusIcon/>
            </motion.button>
        </motion.div>
    )
}