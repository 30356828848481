import RequestHandler from "../../../services/RequestHandler";

export default class ProductRepository {
    async all(shopId: string, categoryId: string): Promise<any> {
        return RequestHandler.get(`${shopId}/products?category_id=${categoryId}`)
    }

    async loadMore(shopId: string, page: number = 1, categoryId?: string): Promise<any> {
        try {
            return await RequestHandler.get(`${shopId}/products?page=${page}&category_id=${categoryId}`);
        } catch (error) {
            console.error("Error loading more products:", error);
            return {status: false, message: "خطا در بارگذاری محصولات بیشتر"};
        }
    }
}