import {useContext, useEffect, useState} from "react";
import {SettingState} from "./interfaces/SettingState";
import SettingController from "./controller/SettingController";
import SettingHeader from "./view/SettingHeader";
import Tabs from "../../components/Tabs";
import TabGenerator from "./view/TabGenerator";
import {MarketContext} from "../Main/context/MarketContext";
import {useNavigate, useParams} from "react-router-dom";

export default function Setting() {
    const context = useContext(MarketContext)
    const navigate = useNavigate();
    const {shopId} = useParams();
    const [state, setState] = useState<SettingState>({
        loading: true,
        error: false,
        activeTab: {label: "طراحی", value: 'theme'},
    })
    const TabList = [
        {label: "طراحی", value: 'theme'},
        {label: "میز ها", value: 'desk'},
        {label: "تخفیف", value: 'discount'},
        {label: "پرینتر", value: 'printer'},
        {label: "سایر", value: 'other'},
    ]


    useEffect(() => {
        // validate setting gateway
        if (!context.state.isAccess) {
            navigate(`/${shopId}`)
        }

        new SettingController(state, setState).getSetting().then()
    }, []);

    // loading
    if (state.loading)
        return (
            <section className={'loading'}>
                <div className="spinner"/>
            </section>
        )

    return (
        <div className={" setting-page"}>
            <section >
                <SettingHeader/>
                <Tabs tabs={TabList}
                      defaultActiveTab={TabList.findIndex(item => item.value === state.activeTab.value)}
                      onTabChange={active => new SettingController(state, setState).changeActiveTab(active)}/>
                <TabGenerator activeTab={state.activeTab.value}/>
            </section>
        </div>
    )
}