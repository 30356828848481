import React, {useState} from "react";
import {ProductState} from "../../interfaces/ProductComponentState";
import StateModalModel from "../../../../components/Modal/Model/StateModalModel";
import ModalBody from "./ImageZoom/view/modalBody";
import ModalComponents from "../../../../components/Modal/Modal";
import BtnAddToCart from "./BtnAddToCart/BtnAddToCart";
import MarketRepository from "../../../Main/repository/MarketRepository";

export default function ProductList({item}: { item: ProductState }) {
    const card = MarketRepository.getCartMarket();
    const [stateModal, setStateModal] = useState<StateModalModel>({
        body: <ModalBody item={item}/>,
        closeLabel: "",
        show: false,
        submit(): void {
        },
        submitLabel: "",
        title: ""
    })

    return (
        <>
            <div className={"product-list-container"}>
                <div className={"product-list-info-container"}>
                    <div className={"product-list-image"}
                         onClick={() => setStateModal({...stateModal, show: true})}
                         style={{backgroundImage: `url(${item.image})`}}/>
                    <BtnAddToCart item={item}/>
                </div>
                <div className={"product-list-info"}>
                    <div className={"product-list-description"}>
                        <h1>{item.title}</h1>
                        <p>{item.description}</p>
                    </div>
                    <div className={"product-list-price"}>
                        <data value={item.price}>{item.price.toLocaleString('fa-IR')}
                            <bdi>{item.currency}</bdi>
                        </data>
                    </div>
                </div>
            </div>
            <ModalComponents state={stateModal} setState={setStateModal}/>
        </>
    )
}