import PriceInterface from "../interface/priceInterface";
import {ReactComponent as PlusIcon} from '../assets/image/plus.svg';
import React, {useEffect} from "react";
import {get} from "lodash";
import InvoiceController from "../Controller/InvoiceController";

export default function ShowDiscount({price, setPrice, setDiscount}: {
    price: PriceInterface,
    setPrice: React.Dispatch<React.SetStateAction<PriceInterface>>
    setDiscount: React.Dispatch<React.SetStateAction<any>>
}) {

    useEffect(() => {
        setDiscount(false)
    }, []);

    return (
        <div className="toggle-form-discount">
            <div>
                <PlusIcon onClick={() => InvoiceController.clearDiscount(price, setPrice)}/>
                <p>تخفیف اعمالی</p>
            </div>
            {price.discountType === 'price' ?
                <p>{new Intl.NumberFormat('fa-IR').format(get(price.discount, "discountPrice", 0))} تومان</p> :
                <p>{get(price.discount, "discountPercentage", 0)}%</p>}
        </div>
    );
}