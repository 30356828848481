import React, {useState} from "react";
import PriceInterface from "../interface/priceInterface";
import InvoiceController from "../Controller/InvoiceController";


export default function DiscountForm({price, setPrice, nonExistentProduct}: {
    price: PriceInterface,
    setPrice: React.Dispatch<React.SetStateAction<PriceInterface>>
    nonExistentProduct: boolean
}) {
    const [state, setState] = useState({
        code: '',
        error: false,
        message: '',
    })

    return (
        <form className={`discount-form ${nonExistentProduct ? 'mt-0' : ""}`}
              onSubmit={(e) => InvoiceController.discountCalculationForm(e, price, setPrice, state, setState)}>
            <div>
                <div>
                    <input type="text" placeholder={'کد تخفیف را وارد کنید'}
                           onChange={e => setState({...state, code: e.target.value})}/>
                </div>
                <button
                    onClick={(e) => InvoiceController.discountCalculationForm(e, price, setPrice, state, setState)}>اعمال
                </button>
            </div>
            {state.error ? <p className={'error'}>{state.message}</p> : null}
        </form>
    );
}