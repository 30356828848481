import AppSettingController from "../../../Main/controller/AppSettingController";

export default function ToggleThemeMode() {
    const setting = AppSettingController.getSetting();

    return (
        <div className={"dark-mode"}>
            <p>تغییر حالت به تاریک</p>
            <div className={"dark-mode-toggle"}>
                <label className="switch">
                    <input type="checkbox"
                           checked={setting.themeMode === 'dark'}
                           onChange={() => AppSettingController.darkModeToggle()}/>
                    <span className="slider round"></span>
                </label>
            </div>
        </div>
    )
}