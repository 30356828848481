import {useState} from "react";
import PrintState from "./interface/PrintState";
import PrintController from "./controller/printController";

export default function PrintForm() {
    const [state, setState] = useState<PrintState>({
        error: false,
        ip: "",
        port: "",
        message: "",
    })

    return (
        <form className="add-discount-form" onSubmit={e => new PrintController(state, setState).addPrinter(e)}>
            <h3>افزودن پرینتر شبکه</h3>
            <div className="inputs-container">
                <label>
                    <input type="text"
                           placeholder={'ای پی پرینتر'}
                           value={state.ip}
                           onChange={e => setState({...state, ip: e.target.value})}/>
                </label>
                <label className={'price-discount'}>
                    <input type="number" value={state.port}
                           placeholder={'پورت پرینتر'}
                           onChange={e => setState({...state, port: e.target.value})}/>
                </label>
                <button type="submit" onClick={e => new PrintController(state, setState).addPrinter(e)}>
                    افزودن
                </button>
            </div>
            {state.error ? <p className={'error'}>{state.message}</p> : null}
        </form>
    );
}