import {useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import Password from "../../../../../components/Password/Password";
import "./assets/passwordModal.scss"
import CheckPassModalState from "./interfaces/CheckPassModalState";
import CheckPassModalController from "./controller/CheckPassModalController";
import {MarketContext} from "../../../../Main/context/MarketContext";

export default function CheckPassModal() {
    const {shopId} = useParams()
    const navigate = useNavigate();
    const context = useContext(MarketContext)
    const [state, setState] = useState<CheckPassModalState>({
        value: "",
    })

    if (!shopId) return null;

    return (
        <div className={"check-pass-modal"}>
            <form
                onSubmit={e => new CheckPassModalController(state, setState).submitPassword(e, context, navigate, shopId)}>
                <div className={'parent-password'}>
                    <Password keyState={"value"}
                              setState={setState}
                              label={"گذرواژه"}
                              placeholder={"گذرواژه"}/>
                    {state.error ? <small>{state.message}</small> : null}
                </div>
                <button type={'submit'} className={"submit-btn"}>تایید</button>
            </form>
        </div>
    )
}