import React from "react";
import {SettingState} from "../interfaces/SettingState";
import MarketContext from "../../Main/interface/MarketContext";
import {NavigateFunction} from "react-router-dom";

export default class SettingController {
    protected state: SettingState;
    protected setState: React.Dispatch<React.SetStateAction<SettingState>>;

    constructor(state: SettingState, setState: React.Dispatch<React.SetStateAction<SettingState>>) {
        this.state = state;
        this.setState = setState;
    }

    async getSetting() {
        this.updateState({loading: false});
    }

    changeActiveTab(active: any) {
        this.updateState({activeTab: active});
    }

    static backToHome(context: MarketContext, navigate: NavigateFunction, shopId: string) {
        context.setState((prevState) => ({...prevState, isAccess: false}))
        return navigate(`/${shopId}`, {replace: true});
    }

    private updateState(state: object) {
        const newState = {...this.state, ...state};
        this.state = newState;
        this.setState(newState)
    }
}
