import MarketRepository from "../repository/MarketRepository";
import {get, isNull, toNumber, unset} from "lodash";
import generateUUID from "../../../utility/generateUUID";
import CreateKey from "../../Pay/controller/CreateKey";
import MarketSettingLocalStorage from "../interface/MarketSettingLocalStorage";
import MarketOrderLocalstorage from "../interface/MarketOrderLocalstorage";

export default class AppSettingController {
    static async getShopData(shopId: string) {
        const data = await MarketRepository.getSetting(shopId)

        if (!get(data, "status", false))
            return false;

        unset(data.date, "theme")

        MarketRepository.setSettingMarket("name", get(data.data, "name", ""));
        MarketRepository.setSettingMarket("phone", get(data.data, "phone", ""));
        MarketRepository.setSettingMarket("textUnderBill", get(data.data, "textUnderBill", ""));
        MarketRepository.setSettingMarket("api_key", get(data.data, "api_key", ""));

        return data.data
    }

    static getExpiredDateOrder() {
        const date = new Date();
        date.setHours(23, 59, 0, 0)
        return date.getTime();
    }

    static changeReceiptBasicNumber(receiptNumber: number) {
        MarketRepository.setSettingMarket("basicReceiptInvoice", receiptNumber)
        return true
    }

    static checkInvoiceNum() {
        const setting = MarketRepository.getSettingMarket();
        const now = Date.now();
        if (!setting.expiredDate || !(now > setting.expiredDate)) return false;

        MarketRepository.setSettingMarket("expiredDate", AppSettingController.getExpiredDateOrder());
        MarketRepository.setSettingMarket("receipt", setting.basicReceiptInvoice)

        return true;
    }

    static darkModeToggle() {
        const setting = MarketRepository.getSettingMarket();
        AppSettingController.setDarkMode(setting.themeMode === 'light' ? "dark" : "light")
        return setting.themeMode;
    }

    static toggleActiveDesk() {
        const setting = MarketRepository.getSettingMarket();
        return MarketRepository.setSettingMarket("activeDesk", !setting.activeDesk);
        return !setting.activeDesk;
    }

    static toggleActiveCustomBuy() {
        const setting = MarketRepository.getSettingMarket();
        return MarketRepository.setSettingMarket("customBuy", !setting.customBuy);
        return !setting.activeDesk;
    }

    static addDesk(label: string) {
        const prevDesks = get(this.getSetting(), "desk", []);
        MarketRepository.setSettingMarket("desk", [
            ...prevDesks,
            {
                id: generateUUID(),
                date: Date.now(),
                label
            }
        ])

        return true
    }

    static addDiscount(discountCode: string, discountPercentage: string, discountPrice: string, type: string) {
        const prevDiscount = get(this.getSetting(), "discount", []);

        MarketRepository.setSettingMarket("discount", [
            ...prevDiscount,
            {
                id: generateUUID(),
                discountCode,
                discountPercentage,
                discountPrice,
                type,
            }
        ])

        return true
    }

    static addPrinter(ip: string, port: string) {
        const prevDiscount = get(this.getSetting(), "printer", []);

        MarketRepository.setSettingMarket("printer", [
            ...prevDiscount,
            {
                id: generateUUID(),
                ip,
                port: toNumber(port)
            }
        ])

        return true
    }

    static deleteDiscount(id: string) {
        const prevDesks = this.getSetting().discount;
        const updatedDesks = prevDesks.filter((item: any) => item.id !== id);
        MarketRepository.setSettingMarket("discount", updatedDesks);
    }

    static deletePrinter(id: string) {
        if (!window.confirm("آیا از حذف پرینتر اطمینان دارید ؟")) return null
        const prevDesks = this.getSetting().printer;
        const updatedDesks = prevDesks.filter((item: any) => item.id !== id);
        MarketRepository.setSettingMarket("printer", updatedDesks);
    }

    static deleteDesk(id: string) {
        const prevDesks = this.getSetting().desk;
        const updatedDesks = prevDesks.filter((item: any) => item.id !== id);
        MarketRepository.setSettingMarket("desk", updatedDesks);
    }

    static changeThemeColor(themeNumber: number) {
        const setting = MarketRepository.getSettingMarket();
        document.body.classList.remove(`theme-${setting.color}`)
        document.body.classList.add(`theme-${themeNumber}`)
        return MarketRepository.setSettingMarket("color", themeNumber);
    }

    static changeReceiptNumber(receiptNumber: number) {
        MarketRepository.setSettingMarket("receipt", receiptNumber)
        return true
    }

    static changeGridColumns(column: number) {
        if (!(column > 5 && column < 1))
            return MarketRepository.setSettingMarket("grid", column);
    }

    static getSetting(key: string | null = null, defaultValue: string | boolean | null = null) {
        if (!key) return MarketRepository.getSettingMarket()

        const data = get(MarketRepository.getSettingMarket(), key)

        if (isNull(data))
            return defaultValue

        return data
    }

    static async passwordHash(password: string) {
        const encoder = new TextEncoder();
        const data = encoder.encode(password);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    }

    static async changePassword(prevPassword: string, newPassword: string) {
        const setting = MarketRepository.getSettingMarket();
        const hashPassword = await AppSettingController.passwordHash(prevPassword)

        if (setting.password !== hashPassword) {
            return false
        }

        const hashNewPassword = await AppSettingController.passwordHash(newPassword)
        MarketRepository.setSettingMarket("password", hashNewPassword)
        return true
    }

    static async checkPassword(password: string) {
        const setting = MarketRepository.getSettingMarket();
        const hashPassword = await AppSettingController.passwordHash(password)
        return setting.password === hashPassword;
    }

    static setDarkMode(themeMode: string) {
        document.body.setAttribute("data-theme", themeMode)
        MarketRepository.setSettingMarket("themeMode", themeMode)
    }

    static setKeys() {
        const setting = MarketRepository.getSettingMarket();
        if (setting.publicKey || setting.publicKey)
            return null

        const keyManager = new CreateKey();
        setTimeout(async () => {
            try {
                const privateKey = await keyManager.getPrivateKey();
                const publicKey = await keyManager.getPublicKey();

                MarketRepository.setSettingMarket("privateKey", privateKey)
                MarketRepository.setSettingMarket("publicKey", publicKey)
            } catch (error) {
            }
        }, 1000);
    }

    static setPublicKeyNetbee(key: string) {
        MarketRepository.setSettingMarket("key", key)
    }

    static setOrder(order: MarketOrderLocalstorage) {
        const prevOrders = get(this.getSetting(), "orders", []);
        MarketRepository.setSettingMarket("orders", [
            ...prevOrders,
            order
        ])

        return true
    }
}