import ProductController from "../../controller/ProductController";
import {useParams} from "react-router-dom";
import {useContext, useEffect} from "react";
import {MarketContext} from "../../../Main/context/MarketContext";

export default function ProductError({message, productController}: {
    message: string,
    productController: ProductController | null
}) {
    const {shopId} = useParams();
    const context = useContext(MarketContext);
    const categoryId = context.state.categoryActive === 'all' ? "" : context.state.categoryActive

    useEffect(() => {
        window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
    });

    return (
        <div className={'error-product-list'}>
            <p>{message}</p>
            <button onClick={e => productController?.initProduct(categoryId, shopId)}>تلاش مجدد</button>
        </div>
    )
}