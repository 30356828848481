import './assets/css/CustomOrderModal.scss'
import React, {useState} from "react";
import {CustomOrderModalState} from "./interface/CustomOrderModalState";
import CustomOrderModalController from "./controller/CustomOrderModalController";

export default function CustomOrderModal() {
    const [state, setState] = useState<CustomOrderModalState>({
        separatorPrice: "",
        price: 0,
    })

    return (
        <div className="custom-order-modal-container">
            <h1>خرید سفارشی</h1>
            <form onSubmit={e => new CustomOrderModalController(state, setState).submit(e)}>
                <label>
                    قیمت خرید سفارشی خود را به تومان وارد کنید
                    <div>
                        <input
                            type="text"
                            inputMode="numeric"
                            value={state.separatorPrice}
                            onChange={(e) => new CustomOrderModalController(state, setState).onChanePriceSeparator(e)}/>
                        <span>تومان</span>
                    </div>
                </label>
                <div className="footer">
                    <div className={'close-modal'} onClick={() => window.dispatchEvent(new Event('closeModal'))}>
                        انصراف
                    </div>
                    <button className={!state.separatorPrice ? "disabled" : ""} type={"submit"}
                            onClick={e => new CustomOrderModalController(state, setState).submit(e)}>
                        افزودن
                    </button>
                </div>
            </form>
        </div>
    );
}