import Theme from "../Theme";
import Other from "../Other";
import Desk from "../Desk";
import Discount from "../Discount";
import Printer from "../Printer";

export default function TabGenerator({activeTab}: { activeTab: string }) {


    switch (activeTab) {
        case "theme":
            return <Theme/>;
        case "desk":
            return <Desk/>;
        case "other":
            return <Other/>;
        case "discount":
            return <Discount/>;
        case "printer":
            return <Printer/>;

        default:
            return <></>
    }
}