import React, {useRef, useEffect, useState} from "react";
import {motion, AnimatePresence} from "framer-motion";
import MarketRepository from "../../../../Main/repository/MarketRepository";
import './assets/buyToolbar.scss';
import {get, sumBy, toNumber} from "lodash";
import MarketCartLocalstorage from "../../../../Main/interface/MarketCartLocalstorage";
import ModalComponents from "../../../../../components/Modal/Modal";
import StateModalModel from "../../../../../components/Modal/Model/StateModalModel";
import Invoice from "../../../../Invoice/Invoice";

export default function BuyToolbar() {
    const card = MarketRepository.getCartMarket() as MarketCartLocalstorage;
    const priceSum = sumBy(card.items, item => item.price * toNumber(get(item, "quantity", 0)));
    const quantitySum = sumBy(card.items, 'quantity');

    const [isVisible, setIsVisible] = useState(false);
    const [height, setHeight] = useState<number | "auto">(0);
    const contentRef = useRef<HTMLDivElement>(null);
    const [stateModal, setStateModal] = useState<StateModalModel>({
        body: <Invoice/>,
        closeLabel: "",
        show: false,
        submit(): void {
        },
        submitLabel: "",
        title: "",
        modalClassName: "payment-modal"
    })

    useEffect(() => {
        if (get(card, "items", []).length) {
            setIsVisible(true);
            if (contentRef.current) {
                setHeight(contentRef.current.scrollHeight);
            }
        } else {
            setHeight(0);
            setTimeout(() => setIsVisible(false), 300);
        }
    }, [card]);

    return (
        <>
            <AnimatePresence>
                {isVisible && (
                    <motion.div
                        className="buy-toolbar-container"
                        initial={{height: 0}}
                        animate={{height}}
                        exit={{height: 0}}
                        transition={{
                            duration: 0.2,
                            ease: "easeIn",
                        }}
                        style={{overflow: "hidden"}}
                        ref={contentRef}
                    >
                        <div>
                            <h3>
                                {`مجموع ${priceSum.toLocaleString('fa-IR')} تومان`} - {`عدد ${quantitySum}`}
                            </h3>
                            <button onClick={() => setStateModal({...stateModal, show: true})}>پرداخت</button>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
            <ModalComponents state={stateModal} setState={setStateModal}/>
        </>
    );
}
