import ToggleCustomBuy from "./view/Other/ToggleCustomBuy";
import ChangePassword from "./view/Other/ChangePassword";
import Receipt from "./view/Other/Receipt/Receipt";
import PublicKey from "./view/Other/PublicKey";
import PublicKeyNetbee from "./view/Other/PublicKeyNetbee";

export default function Other() {


    return (
        <section className={"other-container"}>
            <ToggleCustomBuy/>
            <ChangePassword/>
            <Receipt/>
            <PublicKey/>
            <PublicKeyNetbee/>
        </section>
    )
}