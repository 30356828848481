import React, {useState} from "react";
import {ReactComponent as VisibilityIcon} from "./assets/icon/visibility.svg";
import {ReactComponent as VisibilityOffIcon} from "./assets/icon/visibility-off.svg";

export default function Password({placeholder, label, keyState, setState}: {
    placeholder?: string,
    label?: string,
    keyState?: string,
    setState: React.Dispatch<React.SetStateAction<any>>
}) {
    const [toggleShowPass, setToggleShowPass] = useState<boolean>(false)

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!keyState) return setState(e.target.value);

        return setState((prevState: object) => ({...prevState, [keyState]: e.target.value}))
    }

    return (
        <div className={`password-input ${label ? 'pt-3' : ''}`}>
            <span>{label}</span>
            <div>
                <input type={toggleShowPass ? "text" : "password"} onChange={onChangeHandler}
                       placeholder={placeholder}/>
                <div onClick={() => setToggleShowPass(!toggleShowPass)}>
                    {toggleShowPass ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                </div>
            </div>
        </div>
    )
}