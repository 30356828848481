import React, {useState, useRef, useEffect} from "react";
import generateUUID from "../utility/generateUUID";

interface Tab {
    label: string;
}

interface TabsProps {
    tabs: Tab[];
    onTabChange?: (active: object) => void;
    defaultActiveTab?: number;
}

const Tabs: React.FC<TabsProps> = ({tabs, onTabChange, defaultActiveTab = 0}) => {
    const [activeTab, setActiveTab] = useState<number>(defaultActiveTab);
    const tabsRef = useRef<HTMLDivElement>(null);
    const uniqueId = generateUUID();

    useEffect(() => {
        const updateGliderPosition = () => {
            const glider = document.querySelector(`.glider-${uniqueId}`) as HTMLSpanElement;
            const tabElements = document.querySelectorAll(`.tabs-${uniqueId} .tab`) as NodeListOf<HTMLLabelElement>;
            if (tabElements.length && glider) {
                const tabWidth = tabElements[0].offsetWidth;
                glider.style.transform = `translateX(-${activeTab * tabWidth}px)`;
                glider.style.width = `${tabWidth}px`;
            }
        };

        updateGliderPosition();
        window.addEventListener("resize", updateGliderPosition);

        return () => window.removeEventListener("resize", updateGliderPosition);
    }, [activeTab, tabs]);

    const handleTabChange = (index: number) => {
        setActiveTab(index);

        if (onTabChange) onTabChange(tabs[index]);
    };

    return (
        <div className={`tabs tabs-${uniqueId}`} ref={tabsRef}>
            {tabs.map((tab, index) => (
                <React.Fragment key={`${index}-${tab.label}`}>
                    <input
                        type="radio"
                        id={`radio-${index}-${uniqueId}`}
                        name={`tabs-${uniqueId}`} // استفاده از name یکتا
                        checked={activeTab === index}
                        onChange={() => handleTabChange(index)}
                    />
                    <label className="tab" htmlFor={`radio-${index}-${uniqueId}`}>
                        {tab.label}
                    </label>
                </React.Fragment>
            ))}
            <span className={`glider glider-${uniqueId}`}></span>
        </div>
    )
        ;
};

export default Tabs;
