import AppSettingController from "../../../Main/controller/AppSettingController";

export default function ToggleCustomBuy() {
    const setting = AppSettingController.getSetting();

    return (
        <section>
            <div className={"dark-mode"}>
                <p>خرید سفارشی</p>
                <div className={"dark-mode-toggle"}>
                    <label className="switch">
                        <input type="checkbox"
                               checked={setting.customBuy}
                               onChange={() => AppSettingController.toggleActiveCustomBuy()}/>
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>
        </section>
    )
}