import generateUUID from "../../../utility/generateUUID";
import Ws from "./Ws";
import SignatureManager from "./SignatureManager";
import AppSettingController from "../../Main/controller/AppSettingController";
import {NavigateFunction} from "react-router-dom";
import {get} from "lodash";

export default class PayManager {
    private readonly socketUrl: string = 'ws://localhost:2449';
    private readonly navigate: NavigateFunction;
    private readonly shopId: string;
    private data: any = {};


    constructor(data: any, navigate: NavigateFunction, shopId: string, socketUrl?: string) {
        if (socketUrl)
            this.socketUrl = socketUrl;
        this.data = data;
        this.shopId = shopId;
        this.navigate = navigate;
    }

    async pay(): Promise<{ status: boolean; data?: string }> {
        const uuid: string = generateUUID();
        const wsInstance: Ws = new Ws(this.socketUrl);

        try {
            await wsInstance.connect();
            const requestPayload: string = await this.createRequestPay(this.data.amount, uuid);
            wsInstance.send(requestPayload);

            const responseData = await this.handleWebSocketResponse(wsInstance);
            return { status: responseData.isValid, data: responseData.message };
        } catch {
            wsInstance.close();
            this.navigateToErrorPage();
            return { status: false };
        }
    }

    private async handleWebSocketResponse(wsInstance: Ws): Promise<{ isValid: boolean; message?: string }> {
        return new Promise<{ isValid: boolean; message?: string }>((resolve) => {
            wsInstance.onMessage = async (message: string): Promise<void> => {
                const isValid: boolean = await this.validateMessage(message);
                wsInstance.close();
                resolve({ isValid, message });
            };

            wsInstance.onError = (): void => {
                wsInstance.close();
                resolve({ isValid: false });
            };
        });
    }


    private async validateMessage(message: string): Promise<boolean> {
        try {
            return await this.validationResponse(message);
        } catch {
            return false;
        }
    }

    private navigateToErrorPage(): void {
        this.navigate(`/error/${this.shopId}`, {replace: true});
    }


    private async createRequestPay(amount: number, uuid: string, payload?: string) {
        const setting = AppSettingController.getSetting()

        return JSON.stringify({
            "type": "payment_request",
            "data": {
                "entity_type": "payment_request",
                "amount": amount,
                "stan_id": uuid,
                "payload": payload,
                "sign": await SignatureManager.sign(setting.privateKey, `#${amount},${uuid},${payload ? payload : ''}#`)
            }
        })
    }

    private async validationResponse(message: string) {
        const msg = JSON.parse(message);
        const setting = AppSettingController.getSetting();

        const publicKeyPem = setting.publicKeyNetbee;
        const signature = get(msg, "data.sign", "").trim();
        const data = PayManager.getSignStructure(msg.type, msg)
        const verify = await SignatureManager.verify(publicKeyPem, signature, data)

        if (verify) {
            if (msg.type === "payment_failed") {
                this.navigate(`/error/${this.shopId}`, {replace: true})
                return false;
            }
            if (msg.type === "payment_success") {
                // send data in webHook
                this.navigate(`/success/${this.shopId}`, {replace: true});
                return true;
            }
        }
        this.navigate(`/error/${this.shopId}`, {replace: true});
        return false;
    }

    static getSignStructure(type: "payment_failed" | "payment_success", msg: any): string {
        if (type === "payment_failed") return `#${get(msg, "data.error", "")},${get(msg, "data.stan_id", "")},#`;
        if (type === "payment_success") return `#${get(msg, "data.amount", "")},${get(msg, "data.rrn", "")},${get(msg, "data.serial", "")},${get(msg, "data.trace", "")},${get(msg, "data.card_number", "")},${get(msg, "data.datetime", "")},${get(msg, "data.stan_id", "")},#`;
        return ''
    }
}