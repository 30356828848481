import React from "react";
import {NavigateFunction} from "react-router-dom";
import MarketContext from "../../../../../Main/interface/MarketContext";
import CheckPassModalState from "../interfaces/CheckPassModalState";
import AppSettingController from "../../../../../Main/controller/AppSettingController";

export default class CheckPassModalController {
    protected state: CheckPassModalState;
    protected setState: React.Dispatch<React.SetStateAction<CheckPassModalState>>;

    constructor(state: CheckPassModalState, setState: React.Dispatch<React.SetStateAction<CheckPassModalState>>) {
        this.state = state;
        this.setState = setState;
    }

    async submitPassword(e: React.FormEvent<HTMLFormElement>, context: MarketContext, navigate: NavigateFunction, shopId: string) {
        e.preventDefault()

        if (await AppSettingController.checkPassword(this.state.value)) {
            context.setState((prevState) => ({...prevState, isAccess: true}))
            return navigate(`/setting/${shopId}`, {replace: true});
        }

        return this.updateState({error: true, message: "گذرواژه معتبر نمیباشد."});
    }


    private updateState(state: object) {
        const newState = {...this.state, ...state};
        this.state = newState;
        this.setState(newState)
    }
}
