import React, {useState} from "react";
import {ProductState} from "../../interfaces/ProductComponentState";
import StateModalModel from "../../../../components/Modal/Model/StateModalModel";
import ModalComponents from "../../../../components/Modal/Modal";
import ModalBody from "./ImageZoom/view/modalBody";
import BtnAddToCart from "./BtnAddToCart/BtnAddToCart";


export default function ProductGrid({item}: { item: ProductState }) {
    const [stateModal, setStateModal] = useState<StateModalModel>({
        body: <ModalBody item={item}/>,
        closeLabel: "",
        show: false,
        submit(): void {
        },
        submitLabel: "",
        title: ""
    })

    return (
        <>
            <div className={'product-container'}>
                <div className={`product-image`}
                     onClick={() => setStateModal({...stateModal, show: true})}
                     style={{backgroundImage: `url(${item.image})`}}/>
                <div className={"product-info"}>
                    <div className={"product-description"}>
                        <h1>{item.title}</h1>
                    </div>
                    <div className={"product-price"}>
                        <data value={item.price}>
                            {item.price.toLocaleString('fa-IR')}
                            <bdi>{item.currency}</bdi>
                        </data>
                        <div>
                            <BtnAddToCart item={item}/>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComponents state={stateModal} setState={setStateModal}/>
        </>
    )
}