import React from "react";
import {ReactComponent as GalleryIcon} from "../assets/image/gallery.svg";

export default function ProductImage({image}: { image?: string }) {
    if (!image)
        return (
            <div className="no-image">
                <GalleryIcon/>
            </div>
        )

    return (
        <img src={image} alt=""/>
    );
}