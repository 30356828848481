import Home from "../features/Home/Home";
import Setting from "../features/Setting/Setting";
import Success from "../features/success/Success";
import Error from "../features/error/Error";

function Routes() {


    return [
        {path: "/:shopId", element: <Home/>, title: "داشبورد", index: true},
        {path: "setting/:shopId", element: <Setting/>, title: "تنظیمات", index: true},
        {path: "/success/:shopId", element: <Success/>, title: "پرداخت موفق", index: true},
        {path: "/error/:shopId", element: <Error/>, title: "پرداخت ناموفق", index: true},
    ]
}

export default Routes
