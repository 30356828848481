import React from "react";
import InvoiceState from "../interface/invoiceState";
import {filter, find, get, map, pick, reject, sumBy, toNumber} from "lodash";
import {NavigateFunction} from "react-router-dom";
import PayManager from "../../Pay/controller/PayManager";
import InvoiceGeneratorController from "../../invoiceGenerator/controller/invoiceGeneratorController";
import AppSettingController from "../../Main/controller/AppSettingController";
import MarketSettingLocalStorage from "../../Main/interface/MarketSettingLocalStorage";
import PriceInterface from "../interface/priceInterface";
import MarketRepository from "../../Main/repository/MarketRepository";
import MarketCartLocalstorage, {ProductState} from "../../Main/interface/MarketCartLocalstorage";
import InvoiceRepository from "../repository/invoiceRepository";
import Toast from "../../../utility/toast";
import marketSettingLocalStorage from "../../Main/interface/MarketSettingLocalStorage";
import AppOrdersController from "../../Main/controller/AppOrdersController";
import EventController from "../../../controller/EventController";
import MarketOrderLocalstorage from "../../Main/interface/MarketOrderLocalstorage";
import LocalStorageRepository from "../../../repository/LocalStorageRepository";

export default class InvoiceController {
    protected state: InvoiceState;
    // protected repository: InvoiceState;
    protected setState: React.Dispatch<React.SetStateAction<InvoiceState>>;
    private timeoutId: NodeJS.Timeout | null = null;

    constructor(state: InvoiceState, setState: React.Dispatch<React.SetStateAction<InvoiceState>>) {
        this.state = state;
        this.setState = setState;
    }

    getDeskName() {
        if (this.state.deskActive?.id) {
            return get(this.state.deskActive, "label", "").trim()
        }

        return 'بیرون بر'
    }

    backToSelectDesk() {
        this.setState({takeaway: false, deskActive: undefined, loading: false});
    }

    static filterDesks(search: string, desks?: { label: string, id: string, date: Date }[]) {
        if (!desks?.length) return [];
        return search
            ? desks.filter(item => item.label.toLowerCase().includes(search.toLowerCase()))
            : desks;
    }

    static discountCalculationForm(e: any, price: PriceInterface, setPrice: React.Dispatch<React.SetStateAction<PriceInterface>>, state: any, setState: any) {
        const setting = AppSettingController.getSetting() as MarketSettingLocalStorage;
        const discountCode = find(setting.discount, {discountCode: state.code}) as MarketSettingLocalStorage['discount']
        e.preventDefault();

        if (!discountCode) {
            return setState({
                ...state,
                error: true,
                message: 'کد تخفیف معتبر نمیباشد'
            })
        }

        InvoiceController.discountCalculation(price, setPrice, discountCode)
    }

    static discountCalculation(price: PriceInterface, setPrice: React.Dispatch<React.SetStateAction<PriceInterface>>, discount?: MarketSettingLocalStorage['discount']) {
        const card = MarketRepository.getCartMarket() as MarketCartLocalstorage;
        const priceSum = sumBy(card.items, item => item.price * toNumber(get(item, "quantity", 0)));
        const discountPrice = get(discount, "discountPrice") || get(price.discount, "discountPrice")
        const discountPercentage = get(discount, "discountPercentage") || get(price.discount, "discountPercentage")

        if (get(discount, "type") === "price" || get(price, "discountType", true) === "price")
            return setPrice({
                ...price,
                total: priceSum,
                regular: priceSum - toNumber(discountPrice) < 0 ? 0 : priceSum - toNumber(discountPrice),
                discountType: "price",
                discount: discount ? discount : price.discount as MarketSettingLocalStorage['discount'],
                isApplyDiscount: true
            })

        if (get(discount, "type") === "percentage" || get(price, "discountType", true) === "percentage")
            return setPrice({
                ...price,
                total: priceSum,
                regular: priceSum - (priceSum * toNumber(discountPercentage)) / 100 < 0 ? 0 : priceSum - (priceSum * toNumber(discountPercentage)) / 100,
                discountType: "discountPercentage",
                discount: discount ? discount : price.discount as MarketSettingLocalStorage['discount'],
                isApplyDiscount: true
            })

        if (!get(discount, "type", true) || !get(price, "discountType", true))
            return setPrice({
                ...price,
                total: priceSum,
                regular: priceSum,
                discountType: "",
                isApplyDiscount: false
            })
    }

    static clearDiscount(price: PriceInterface, setPrice: React.Dispatch<React.SetStateAction<PriceInterface>>) {
        const card = MarketRepository.getCartMarket() as MarketCartLocalstorage;
        const priceSum = sumBy(card.items, item => item.price * toNumber(get(item, "quantity", 0)));
        return setPrice({
            ...price,
            total: priceSum,
            regular: priceSum,
            discountType: "",
            isApplyDiscount: false,
            discount: undefined,
        })
    }

    async sendPayRequest(price: PriceInterface, navigator: NavigateFunction, shopId: string
        , setNotAvailableProduct: React.Dispatch<React.SetStateAction<[]>>) {
        const setting = AppSettingController.getSetting() as MarketSettingLocalStorage;
        const card = MarketRepository.getCartMarket() as MarketCartLocalstorage;
        const desk = this.state.takeaway ? 'بیرون بر' : this.state.deskActive?.label;
        const now = new Date().toLocaleDateString();

        this.setState({...this.state, loading: true});
        const filteredItems = reject(card.items, (item: ProductState) => get(item, "customProduct") === true);
        const checkProduct = await this.checkOrders(filteredItems as ProductState[], shopId)
        if (!get(checkProduct, "payment", true) && !filteredItems.length) {
            setNotAvailableProduct(get(checkProduct, "invalidItems", []) as [])
            this.setState({...this.state, loading: false});
            return null
        }

        setNotAvailableProduct([])

        const products = map(card.items, (item: any) => ({
            count: item.quantity,
            id: item._id,
            name: item.title,
            price: item.price
        }));

        if (price.regular > 0) {
            const paymentSuccess = await new PayManager({amount: price.regular * 10}, navigator, shopId).pay();

            if (paymentSuccess.status && paymentSuccess.data) {
                AppOrdersController.setOrder({
                    shopId,
                    price,
                    order: {
                        products: products,
                        payAt: now,
                        rrn: get(JSON.parse(get(paymentSuccess, "data", "")), "data.rrn", ""),
                        trace: get(JSON.parse(get(paymentSuccess, "data", "")), "data.trace", ""),
                    },
                    syncServer: false,
                    desk: setting.activeDesk ? desk : undefined
                })

                EventController.dispatch("syncServer")

                this.scheduleAction(async () => {
                        await new InvoiceGeneratorController().print(price, setting.activeDesk ? desk : undefined)

                        setting.printer?.map(async (item) => {
                            await new InvoiceGeneratorController().print(price, setting.activeDesk ? desk : undefined, item.ip, item.port)
                        })
                    },
                    toNumber(setting.receipt) + 1
                );
            }
        } else if (price.regular === 0) {
            AppOrdersController.setOrder({
                shopId,
                price,
                order: {
                    products: products,
                    payAt: now,
                    rrn: '',
                    trace: '',
                },
                syncServer: false,
                desk: setting.activeDesk ? desk : undefined
            })

            EventController.dispatch("syncServer")

            await new InvoiceGeneratorController().print(price, setting.activeDesk ? desk : undefined)

            setting.printer?.map(async (item) => {
                await new InvoiceGeneratorController().print(price, setting.activeDesk ? desk : undefined, item.ip, item.port)
            })

            AppSettingController.changeReceiptNumber(toNumber(setting.receipt) + 1)

            navigator(`/success/${shopId}`, {replace: true});
            this.setState({...this.state, loading: false});
        }

        this.setState({...this.state, loading: false});
    }

    async checkOrders(items: ProductState[], shopId: string) {
        if (!items.length) return null
        const card = MarketRepository.getCartMarket() as MarketCartLocalstorage;
        const result = items.map(item => pick({
            id: item._id,
            name: item.title,
            count: item.quantity + 1,
            price: item.price
        }, ['id', 'name', 'count', 'price']));

        const isAvailable = await InvoiceRepository.checkOrder(result as any, shopId);

        if (!get(isAvailable, "status", false)) {
            Toast.error("خطا در دریافت اطلاعات لطفا دوباره تلاش کنید");
            return null;
        }

        const updatedArray2 = map(card.items, item2 => {
            const matchedItem = find(get(isAvailable, "data", []), item1 => item1.id === item2._id);
            return {
                ...item2,
                can: matchedItem ? matchedItem.can : false
            };
        });

        const invalidItems = filter(updatedArray2, item => !item.can);
        const paymentStatus = invalidItems.length === 0;

        return {
            payment: paymentStatus,
            invalidItems: paymentStatus ? [] : invalidItems
        };
    }

    static async setOrderServer() {
        const waiting = LocalStorageRepository.get("waiting") as string
        if (JSON.parse(waiting)) return null;

        LocalStorageRepository.set("waiting", true)

        const now = new Date().toLocaleDateString('fa-IR');
        const orders = AppOrdersController.getSetting() as MarketOrderLocalstorage[];
        if (!orders.length) {
            LocalStorageRepository.set("waiting", false);
            return false;
        }

        orders.map(async (order) => {
            const res = await InvoiceRepository.orderSet(order.shopId, order.price, {
                products: order.order.products,
                payAt: now,
                rrn: order.order.rrn,
                trace: order.order.trace,
            }, order.desk)

            if (get(res, "status", false)) {
                AppOrdersController.deleteOrder(order)
            }
        })

        LocalStorageRepository.set("waiting", false)
    }

    private clearTimeoutIfExists(): void {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
    }

    private scheduleAction(action: () => Promise<void>, receipt?: number, delay: number = 1000): void {
        this.clearTimeoutIfExists();
        this.timeoutId = setTimeout(async () => {
            await action();
            if (receipt) AppSettingController.changeReceiptNumber(receipt);
        }, delay);
    }

    private updateState(state: object) {
        const newState = {...this.state, ...state};
        this.state = newState;
        this.setState(newState)
    }
}