import React from "react";
import PrintState from "../interface/PrintState";
import AppSettingController from "../../../../Main/controller/AppSettingController";
import validateIP from "../../../../../utility/validateIp";

export default class PrintController {
    protected state: PrintState;
    protected setState: React.Dispatch<React.SetStateAction<PrintState>>;

    constructor(state: PrintState, setState: React.Dispatch<React.SetStateAction<PrintState>>) {
        this.state = state;
        this.setState = setState;
    }

    async addPrinter(e: any) {
        const numberRegex = /^-?\d+(\.\d+)?$/;
        e.preventDefault();
        if (!this.state.ip) return this.updateState({
            ...this.state,
            error: true,
            message: "ip پرینتر اجباری میباشد"
        });

        if (!validateIP(this.state.ip)) return this.updateState({
            ...this.state,
            error: true,
            message: "ای پی پرینتر معتبر نمیباشد"
        });

        if (!this.state.port || !numberRegex.test(this.state.port)) return this.updateState({
            ...this.state,
            error: true,
            message: "پورت پرینتر اجباری میباشد"
        });

        this.setState({
            error: false,
            ip: "",
            port: "",
            message: ""
        })

        AppSettingController.addPrinter(
            this.state.ip,
            this.state.port,
        )
    }

    private updateState(state: object) {
        const newState = {...this.state, ...state};
        this.state = newState;
        this.setState(newState)
    }
}