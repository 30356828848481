import {useEffect, useState} from "react";
import {HomeState} from "./Interfaces/HomeState";
import HomeController from "./controller/HomeController";
import Category from "../Categories/Category";
import Products from "../Products/Products";
import HomeHeader from "./view/component/HomeHeader";
import {useParams} from "react-router-dom";

export default function Home() {
    const {shopId} = useParams();
    const [state, setState] = useState<HomeState>({
        loading: true,
    })

    useEffect(() => {
        if (shopId)
            new HomeController(state, setState).init(shopId as string).then()
    }, [shopId]);

    return (
        <div>
            <section>
                <HomeHeader/>
                <Category/>
                <Products/>
            </section>
        </div>
    )
}