import './assets/css/error.scss'
import Lottie from 'react-lottie';
import loadingAnimation from '../../assets/images/error.json'
import {NavLink, useNavigate, useParams} from "react-router-dom";
import useCountDown from "../../utility/useCountDown";
import {useEffect} from "react";

export default function Error() {
    const count = useCountDown(13);
    const navigate = useNavigate();
    const {shopId} = useParams();
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        if (!count) {
            navigate(`/${shopId}`, {replace: true});
        }
    }, [count]);

    return (
        <section className={'error-container'}>
            <div className={'content'}>
                <div className="logo">
                    <Lottie
                        options={defaultOptions}
                        isClickToPauseDisabled
                        height={120}
                        width={120}
                    />
                </div>
                <div className={'title'}>
                    <h1>پرداخت نا‌موفق</h1>
                    <p>لطفاً دوباره تلاش کنید.</p>
                </div>
                <div className="footer">
                    <NavLink to={`/${shopId}`}>بازگشت</NavLink>
                    <p>بازگشت خودکار در {count} ثانیه دیگر</p>
                </div>
            </div>
        </section>
    )
}