import React from "react";
import {CustomOrderModalState} from "../interface/CustomOrderModalState";
import Toast from "../../../../utility/toast";
import AppCartController from "../../../Main/controller/AppCartController";

export default class CustomOrderModalController {
    protected state: CustomOrderModalState;
    protected setState: React.Dispatch<React.SetStateAction<CustomOrderModalState>>;

    constructor(state: CustomOrderModalState, setState: React.Dispatch<React.SetStateAction<CustomOrderModalState>>) {
        this.state = state;
        this.setState = setState;
    }

    onChanePriceSeparator(e: React.ChangeEvent<HTMLInputElement>) {
        const rawValue = e.target.value.replace(/[^0-9]/g, "");
        const formattedValue = rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        this.setState({
            ...this.state,
            separatorPrice: formattedValue,
            price: Number(rawValue),
        });
    }

    submit(e: any) {
        e.preventDefault();
        if (!this.state.price)
            return Toast.error("مبلغ الزامی میباشد")

        if (AppCartController.addCustomOrder(this.state.price)) {
            Toast.success("مبلغ سفارشی به سبد خرید افزوده شد")
            return window.dispatchEvent(new Event('closeModal'))
        }

        Toast.error("خطا در ذخیره سازی مبلغ سفارشی")
    }
}