import {useState} from "react";
import DiscountFormState from "./interface/DiscountFormState";
import DiscountFormController from "./Controller/DiscountFormController";

export default function DiscountForm() {
    const [state, setState] = useState<DiscountFormState>({
        error: false,
        discountCode: "",
        discountPercentage: "",
        discountPrice: "",
        discountPriceSeparator: "",
        type: "percentage",
        message: "",
    })

    return (
        <form className="add-discount-form" onSubmit={e => new DiscountFormController(state, setState).addDiscount(e)}>
            <h3>افزودن کد تخفیف</h3>
            <div className="inputs-container">
                <label>
                    <input type="text"
                           placeholder={'کد تخفیف'}
                           value={state.discountCode}
                           onChange={e => new DiscountFormController(state, setState).setDiscountCode(e)}/>
                </label>
                <select onChange={e => new DiscountFormController(state, setState).changeType(e)}>
                    <option value="percentage">درصد</option>
                    <option value="price">مبلغ</option>
                </select>
                <label className={'price-discount'}>
                    {state.type === "percentage" ?
                        <input type="text" value={state.discountPercentage}
                               placeholder={'درصد تخفیف'}
                               inputMode={'numeric'}
                               onChange={e => new DiscountFormController(state, setState).setDiscount(e)}/> :
                        <input type="text" value={state.discountPrice}
                               placeholder={"مبلغ تخفیف"}
                               inputMode={'numeric'}
                               onChange={e => new DiscountFormController(state, setState).setDiscount(e)}/>
                    }
                    {state.type === "price" ? <p>{Number(state.discountPrice).toLocaleString('fa-IR')} تومان</p> : null}
                </label>
                <button type="submit" onClick={e => new DiscountFormController(state, setState).addDiscount(e)}>
                    افزودن
                </button>
            </div>
            {state.error ? <p className={'error'}>{state.message}</p> : null}
        </form>
    );
}