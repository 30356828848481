import RequestHandler from "../../../services/RequestHandler";
import PriceInterface from "../interface/priceInterface";
import {get} from "lodash";
import AppSettingController from "../../Main/controller/AppSettingController";
import MarketSettingLocalStorage from "../../Main/interface/MarketSettingLocalStorage";

export default class InvoiceRepository {
    static async checkOrder(items: {
        id: string,
        name: string,
        count: string,
        price: string
    }[], shopId: string) {
        return RequestHandler.post(`${shopId}/orders/check`, {products: items}, {'Content-Type': 'application/json'})
    }

    static async orderSet(shopId: string, price: PriceInterface, order: {
        products: {
            count: number,
            id: string,
            name: string,
            price: string,
        }[],
        payAt: string,
        rrn?: string,
        trace?: string,
    }, desk?: string) {
        const setting = AppSettingController.getSetting() as MarketSettingLocalStorage;
        // ref_code => rrn/trace
        return RequestHandler.post(`${shopId}/orders`, {
            pay_at: order.payAt,
            products: order.products,
            status: "purchased",
            total_price: get(price, "regular", ""),
            order_id: setting.receipt,
            desk_name: desk,
            ref_code: `${get(order, "rrn", "")}/${get(order, "trace", "")}`,
        }, {'Content-Type': 'application/json'})
    }
}