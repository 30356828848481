import ReceiptController from "./controller/ReceiptController";
import React, {useState} from "react";
import {ReceiptState} from "./interfaces/ReceiptState";
import MarketRepository from "../../../../Main/repository/MarketRepository";

export default function Receipt() {
    const setting = MarketRepository.getSettingMarket();
    const [state, setState] = useState<ReceiptState>({
        value: setting.receipt
    })

    return (
        <section onSubmit={e => new ReceiptController(state, setState).saveFirstReceipt(e)}>
            <p>شمراه اولین فیش</p>
            <div className={'form-receipt'}>
                <form>
                    <input type="number"
                           defaultValue={state.value}
                           onChange={e => new ReceiptController(state, setState).receiptInput(e)}/>
                    <button type="submit">تغییر</button>
                </form>
                {state.error ? <small>{state.message}</small> : null}
            </div>
        </section>
    )
}