import {Route, Routes, useLocation} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {MarketContext} from "../context/MarketContext";
import MarketContextState from "../interface/MarketContextState";
import RouteList from "./../../../routes/Routes";
import Home from "../../Home/Home";
import {get} from "lodash";
import MarketController from "../controller/MarketController";
import {Toaster} from "react-hot-toast";
import EventController from "../../../controller/EventController";

function Main() {
    const location = useLocation();
    const [count, setCount] = useState(0);
    const isIntervalRunning = useRef(false);
    const [state, setState] = useState<MarketContextState>({
        categoryActive: 'all',
        isAccess: false
    });

    useEffect(() => count === 0 ? (new MarketController(count, setCount)).init() : undefined, [count])

    useEffect(() => count !== 0 ? (new MarketController(count, setCount)).start() : undefined, [location.pathname])

    useEffect(() => {
        if (count !== 0 && !isIntervalRunning.current) {
            isIntervalRunning.current = true;
            const interval = setInterval(() => {
                EventController.dispatch("syncServer")
            }, 10000);

            return () => {
                clearInterval(interval);
                isIntervalRunning.current = false;
            };
        }
    }, [count, location.pathname]);

    return (
        <MarketContext.Provider value={{state, setState}}>
            <Routes>
                <Route index element={<Home/>}/>
                {
                    RouteList().map(
                        (item, index) =>
                            <Route key={`route-${index}-${item.path}`} path={item.path} element={item.element}
                                   index={get(item, "index", false)}/>
                    )
                }
            </Routes>
            <Toaster position="top-center" reverseOrder={false}/>
        </MarketContext.Provider>
    )
}

export default Main;