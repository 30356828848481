import './assets/addDiscount.scss'
import DiscountForm from "./view/Discount/DiscountForm";
import DiscountCodsList from "./view/Discount/DiscountCodsList";

export default function Discount() {
    return (
        <section className="discount-container">
            <section>
                <DiscountForm/>
                <DiscountCodsList/>
            </section>
        </section>
    );
}