import ToggleActiveDesk from "./view/Desks/ToggleActiveDesk";
import DeskForm from "./view/Desks/DeskForm/DeskForm";
import DeskList from "./view/Desks/DeskList";
import AppSettingController from "../Main/controller/AppSettingController";

export default function Desk() {
    const setting = AppSettingController.getSetting();

    return (
        <section className={"desk-container"}>
            <ToggleActiveDesk/>
            {setting.activeDesk ? <DeskForm/> : null}
            {setting.activeDesk ? <DeskList/> : null}
        </section>
    )
}