import {get} from "lodash";
import AppSettingController from "../../../Main/controller/AppSettingController";
import {ReactComponent as DeleteIcon} from "../../assets/icons/delete.svg";
import MarketSettingLocalStorage from "../../../Main/interface/MarketSettingLocalStorage";

export default function PrinterList() {
    const setting = AppSettingController.getSetting() as MarketSettingLocalStorage;


    return (
        <div className="discount-cods-list-container">
            <div className="desk-list">
                <div className={'desk-list-header'}>
                    <h3>لیست پرینتر</h3>
                </div>
                <div className="desk-list-item">
                    {get(setting, "printer", []).map((item: {
                        id: string,
                        ip: string,
                        port: number,
                    }) => (
                        <div key={item.id} className={'desk-item'}>
                            <p>ای پی پرینتر : {item.ip}:{item.port}</p>
                            <DeleteIcon onClick={e => AppSettingController.deletePrinter(item.id)}/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}