import {ProductState} from "../../../../interfaces/ProductComponentState";
import React from "react";
import ImageZoom from "./ImageZoom";
import BtnAddToCart from "../../BtnAddToCart/BtnAddToCart";

export default function ModalBody({item}: { item: ProductState }) {

    return (
        <div className="modal-body-container">
            <div className={'product-image-modal-container'}>
                <ImageZoom image={item.image}/>
            </div>
            <div className="product-info">
                <div className="product-description">
                    <h1>{item.title}</h1>
                    <p>{item.description}</p>
                </div>
                <div className="product-price">
                    <data value={item.price}>
                        {item.price.toLocaleString('fa-IR')}
                        <bdi>{item.currency}</bdi>
                    </data>
                    <div>
                        <button onClick={() => window.dispatchEvent(new Event('closeModal'))} className="btn-back">
                            بستن
                        </button>
                        <BtnAddToCart item={item}/>
                    </div>
                </div>
            </div>
        </div>
    );
}
