import React from "react";
import {ImageZoomState} from "../interfaces/ImageZoomState";

export default class ImageZoomController {
    protected state: ImageZoomState;
    protected setState: React.Dispatch<React.SetStateAction<ImageZoomState>>;

    constructor(state: ImageZoomState, setState: React.Dispatch<React.SetStateAction<ImageZoomState>>) {
        this.state = state;
        this.setState = setState;
    }

    handleZoomChange = (scale: number, resetTransform: () => void) => {
        if (scale <= 1) {
            this.updateState({isZoom: false, isPanning: false});
            resetTransform();
        } else {
            this.updateState({isZoom: true, isPanning: true});
        }
    }

     handleDoubleClick = (toggleZoomIn: () => void, resetTransform: () => void) => {
        if (this.state.isZoom) {
            this.updateState({isZoom: false});
            resetTransform();
        } else {
            this.updateState({isZoom: true});
            toggleZoomIn();
        }
    };

    private updateState(state: object) {
        const newState = {...this.state, ...state};
        this.state = newState;
        this.setState(newState)
    }
}