import Tabs from "../../../../components/Tabs";
import ProductController from "../../../Products/controller/ProductController";
import {get, toNumber} from "lodash";
import AppSettingController from "../../../Main/controller/AppSettingController";

export default function LayoutProduct() {
    const setting = AppSettingController.getSetting();
    const grid_list = [
        {label: "لیست"},
        {label: "چند ستونه"},
    ];
    const grid_columns = [
        {label: "1"},
        {label: "2"},
        {label: "3"},
        {label: "4"},
        {label: "5"},
    ];

    return (
        <>
            <div className={"shop-arrangement"}>
                <p>چیدمان صفحه محصولات</p>
                <div className={'shop-arrangement-selector'}>
                    <Tabs tabs={grid_list} defaultActiveTab={setting.isList ? 0 : 1}
                          onTabChange={() => ProductController.productArrangementToggle()}/>
                </div>
            </div>
            <div className={`grid-template ${!setting.isList ? 'show-grid-template' : ''}`}>
                <p>تعداد ستون ها</p>
                <div className={"grid-template-selector"}>
                    <Tabs tabs={grid_columns}
                          defaultActiveTab={setting.grid - 1}
                          onTabChange={(active) => AppSettingController.changeGridColumns(toNumber(get(active, "label")))}/>
                </div>
            </div>
        </>
    )
}