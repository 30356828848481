import React from "react";
import {ProductComponentState, ProductState} from "../interfaces/ProductComponentState";
import ProductRepository from "../repository/ProductRepository";
import {get, toNumber} from "lodash";
import LocalStorageRepository from "../../../repository/LocalStorageRepository";
import AppCartController from "../../Main/controller/AppCartController";
import PriceInterface from "../../Invoice/interface/priceInterface";
import InvoiceController from "../../Invoice/Controller/InvoiceController";

export default class ProductController {
    protected state: ProductComponentState;
    protected repository: ProductRepository;
    protected setState: React.Dispatch<React.SetStateAction<ProductComponentState>>;
    protected scrollPosition: number = 0;

    constructor(state: ProductComponentState, setState: React.Dispatch<React.SetStateAction<ProductComponentState>>) {
        this.state = state;
        this.setState = setState;
        this.repository = new ProductRepository();
    }

    async initProduct(categoryId: string, shopId?: string) {
        this.updateState({error: false, loading: true, hasMore: true, products: []});

        if (!shopId) {
            return this.updateState({loading: false, error: true, message: 'لینک فروشگاه معتبر نمیباشد.'});
        }

        const data = await this.repository.all(shopId, categoryId);

        if (!get(data.data, "data", []).length) {
            return this.updateState({loading: false, error: true, message: 'محصولی یافت نشد'});
        }

        if (get(data, "status", false)) {
            this.updateState({
                loading: false,
                error: false,
                totalPage: get(data.data, "last_page"),
                currentPage: get(data.data, "current_page"),
                products: get(data.data, "data", []),
            });
        }
    }

    async loadMoreProducts(shopId: string, page: number, categoryId: string) {
        if (!shopId || this.state.currentPage === this.state.totalPage) return;

        this.updateState({loading: true});
        const data = await this.repository.loadMore(shopId, page, categoryId);

        if (data.status && data.data?.data?.length) {
            this.updateState({
                loading: false,
                products: [...this.state.products, ...data.data.data],
                currentPage: page,
                hasMore: true,
            });
        } else {
            this.updateState({
                loading: false,
                hasMore: false,
            });
        }
    }

    async handleScroll(shopId: string, categoryId: string) {
        if (
            window.innerHeight + window.scrollY >= document.body.offsetHeight - 1000 &&
            this.state.hasMore &&
            !this.state.loading
        ) {
            await this.loadMoreProducts(shopId, this.state.currentPage + 1, categoryId);
        }
    }

    static productArrangementToggle() {
        const setting = JSON.parse(LocalStorageRepository.get("setting") as string);
        LocalStorageRepository.set("setting", {...setting, isList: !setting.isList});
        return !setting.isList;
    }

    static addToCart(item: ProductState, state: boolean, setState: React.Dispatch<React.SetStateAction<boolean>>) {
        setState(!state)
        return toNumber(item.total) > 0 ? AppCartController.addItem(item) : null
    }

    static removeToCart(item: ProductState, state: boolean, setState: React.Dispatch<React.SetStateAction<boolean>>) {
        setState(!state)
        AppCartController.removeItem(item)
    }

    static addToInvoiceCart(item: ProductState, state: boolean, setState: React.Dispatch<React.SetStateAction<boolean>>, price: PriceInterface, setPrice: React.Dispatch<React.SetStateAction<PriceInterface>>) {
        setState(!state)
        InvoiceController.discountCalculation(price, setPrice)
        return toNumber(item.total) > 0 ? AppCartController.addItem(item) : null
    }

    static removeToInvoiceCart(item: ProductState, state: boolean, setState: React.Dispatch<React.SetStateAction<boolean>>, price: PriceInterface, setPrice: React.Dispatch<React.SetStateAction<PriceInterface>>) {
        setState(!state)
        InvoiceController.discountCalculation(price, setPrice)
        AppCartController.removeItem(item)
    }

    private updateState(state: object) {
        const newState = {...this.state, ...state};
        this.state = newState;
        this.setState(newState);
    }
}
