import {HomeState} from "../Interfaces/HomeState";
import HomeRepository from "../repository/HomeRepository";
import React from "react";
import AppSettingController from "../../Main/controller/AppSettingController";

export default class HomeController {
    protected state: HomeState;
    protected repository: HomeRepository;
    protected setState: React.Dispatch<React.SetStateAction<HomeState>>;

    constructor(state: HomeState, setState: React.Dispatch<React.SetStateAction<HomeState>>) {
        this.state = state;
        this.setState = setState;
        this.repository = new HomeRepository();
    }

    async init(shopId: string) {
       await AppSettingController.getShopData(shopId)
    }

    private updateState(state: object) {
        const newState = {...this.state, ...state};
        this.state = newState;
        this.setState(newState)
    }
}
