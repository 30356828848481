import {createContext} from "react";
import MarketContextState from "../interface/MarketContext";

export const MarketContext = createContext<MarketContextState>({
    state: {
        categoryActive: 'all',
        isAccess: false
    },
    setState: () => {
    }
})